import { useEffect, useState, useRef } from "react";
import { Container, Row, Button, Alert, Overlay, } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faBinoculars, faCogs, faBan, faCalendar, faInfo, faTimes, faRetweet, faWrench, faCheck, faFilter } from '@fortawesome/free-solid-svg-icons';
import EvoDataService from "../../Dataservices/EvoDataService";
import './EvoMonitor.css';
import { addDaysToDate, formatDateAsDashString, getPlainDateFromDashStringDate } from "../../Functions/DateTimeHelper";
import { getErrorMessage } from "../../Functions/CommonHelper";
import DateRangePicker from '../PublicComponents/DateRangePicker'
import SummaryDropdown from "../PublicComponents/SummaryDropdown";
import PaginatedTable from '../PublicComponents/PaginatedTable'
import uuid from 'react-uuid'
import { useHistory } from 'react-router-dom';

function EvoJobSummary() {
    const SearchParams = useRef({
        START_DATE: "startDate",
        END_DATE: "endDate",
        TAB: "tab"
    });

    const JobStatus = useRef({
        SCHEDULED: "Scheduled",
        NON_RETRIABLE_ERROR: "NonRetriableError",
        RETRIABLE_ERROR: "RetriableError",
        CANCELLED: "Cancelled",
        IN_PROGRESS: "InProgress",
        NEW: "New",
        SUCCESS: "Success",
    });

    const OverallStatus = useRef({
        FIX: "Fix",
        MONITOR: "Monitor",
        NO_ACTION_NEEDED: "NoActionNeeded",
    });

    const StatusDefinitionMap = useRef({
        [JobStatus.current.SCHEDULED]: "Scheduled - The job is planned but not yet executed.",
        [JobStatus.current.NON_RETRIABLE_ERROR]: "Non-Retriable Error - A permanent error that cannot be retried.",
        [JobStatus.current.RETRIABLE_ERROR]: "Retriable Error - An error that can be retried.",
        [JobStatus.current.CANCELLED]: "Cancelled - The job has been cancelled and will not proceed.",
        [JobStatus.current.IN_PROGRESS]: "In Progress/New - The job is in progress or newly created.",
        [JobStatus.current.SUCCESS]: "Success - The job has completed successfully.",
        [OverallStatus.current.MONITOR]: "Monitor - The cube is running but has previously failed",
        [OverallStatus.current.FIX]: "Fix - The cube needs to be fixed",
        [OverallStatus.current.NO_ACTION_NEEDED]: "No Action needed - The cube is running successfully",
    });

    const ColumnsNames = useRef({
        OVERALL: "Overall",
        CUBE_NAME: "CubeName",
    });

    const IconMap = useRef({
        [JobStatus.current.SCHEDULED]: { icon: faCalendar, color: 'gray' },
        [JobStatus.current.NON_RETRIABLE_ERROR]: { icon: faTimesCircle, color: 'red' },
        [JobStatus.current.RETRIABLE_ERROR]: { icon: faRetweet, color: 'orange' },
        [JobStatus.current.CANCELLED]: { icon: faBan, color: 'orange' },
        [JobStatus.current.IN_PROGRESS]: { icon: faCogs, color: 'blue' },
        [JobStatus.current.NEW]: { icon: faCogs, color: 'blue' },
        [JobStatus.current.SUCCESS]: { icon: faCheckCircle, color: 'green' },
        [OverallStatus.current.MONITOR]: { icon: faBinoculars, color: 'orange' },
        [OverallStatus.current.FIX]: { icon: faWrench, color: 'red' },
        [OverallStatus.current.NO_ACTION_NEEDED]: { icon: faCheck, color: 'green' },
    });

    const [startDate, setStartDate] = useState(addDaysToDate(new Date(), -7));
    const [endDate, setEndDate] = useState(new Date());
    const [error, setError] = useState('');
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [allData, setAllData] = useState([]);
    const [filteredAllData, setAllFilteredData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [show, setShow] = useState(false);
    const [fetchDataFlag, setFetchDataFlag] = useState(false);
    const [overAllFilter, setOverAllFilter] = useState({
        [ColumnsNames.current.OVERALL]: Object.keys(OverallStatus.current).reduce((acc, status) => {
            acc[OverallStatus.current[status]] = true;
            return acc;
        }, {})
    }
    );

    const history = useHistory();
    const target = useRef(null);

    useEffect(() => {
        let params = new URLSearchParams(history.location.search);

        const startDateValue = params.get(SearchParams.current.START_DATE);
        const endDateValue = params.get(SearchParams.current.END_DATE);

        if (startDateValue && endDateValue && new Date(startDateValue) < new Date(endDateValue)) {
            const formatedStartDate = getPlainDateFromDashStringDate(startDateValue);
            const formatedEndDate = getPlainDateFromDashStringDate(endDateValue);
            setStartDate(new Date(formatedStartDate));
            setEndDate(new Date(formatedEndDate));
            setFetchDataFlag(true);
        } else {
            setStartDate(addDaysToDate(new Date(), -7));
            setEndDate(new Date());
            setFetchDataFlag(true);
        }
    }, []);

    useEffect(() => {
        if (fetchDataFlag) {
            fetchData();
            setFetchDataFlag(false);
        }
    }, [startDate, endDate, fetchDataFlag]);

    useEffect(() => {
        let tempData = []
        for (let row of allData) {
            if (overAllFilter[ColumnsNames.current.OVERALL][row[ColumnsNames.current.OVERALL][0]]) {
                tempData.push(row);
            }
        }
        setAllFilteredData(tempData);
    }, [overAllFilter]);

    const fetchData = async () => {
        setError('');
        setAllData([]);
        setIsPageLoading(true);

        try {
            let activeCubes = (await EvoDataService.getActiveCubes()).data;
            let cubes = [];

            for (let cube of activeCubes) {
                cubes.push(cube.cubeId);
            }

            let cubesStatus = (await getMonitoredCubesStatus(cubes)) ?? []
            const daysBetween = getDaysBetweenDates(startDate, endDate);
            let tempAllData = []
            for (let cubeStatus of cubesStatus) {

                let datesRow = {}
                const cubeName = activeCubes.find(entry => entry.cubeId === cubeStatus.cubeId).cubeName;

                cubeStatus.datesStatus.map(statusBetweenDates => {
                    const fromDate = new Date(statusBetweenDates.from)
                    const toDate = new Date(statusBetweenDates.to)
                    const rangeDate = `${getHoursAndMinutes(fromDate)}_${getHoursAndMinutes(toDate)}`
                    const dayDate = fromDate.toLocaleDateString("en-US")

                    if (!datesRow[dayDate]) {
                        datesRow[dayDate] = []
                    }

                    datesRow[dayDate][rangeDate] = statusBetweenDates;
                });

                datesRow[ColumnsNames.current.OVERALL] = [cubeStatus.overallStatus, statusMapper(cubeStatus.overallStatus, 0, cubeStatus.overallStatus)];
                datesRow[ColumnsNames.current.CUBE_NAME] = [cubeName, getServiceLogName(cubeName)];
                tempAllData.push(datesRow)

            }

            const calculatedColumns = [[ColumnsNames.current.CUBE_NAME, "Cube Name"], [ColumnsNames.current.OVERALL, "Overall"], ...daysBetween.map(date => { return [date, date] })];
            setAllData(tempAllData);
            setAllFilteredData(tempAllData);
            setColumns(calculatedColumns);
        } catch (error) {
            console.error(getErrorMessage(error, true));
            setError(getErrorMessage(error));
        }
        setIsPageLoading(false);
    }

    const getMonitoredCubesStatus = async (cubes) => {
        try {
            let data = {
                From: formatDateAsDashString(startDate),
                To: formatDateAsDashString(endDate),
                Cubes: cubes
            }
            const res = await EvoDataService.getMonitoredCubesStatus(data);
            return res.data;
        } catch (error) {
            console.error(getErrorMessage(error, true));
            setError(getErrorMessage(error));
        }
    };

    const getDaysBetweenDates = (startDate, endDate) => {
        const dateArray = [];
        let currentDate = new Date(endDate);
        currentDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        while (currentDate >= startDate) {
            dateArray.push(currentDate.toLocaleDateString("en-US"));
            currentDate.setDate(currentDate.getDate() - 1);
        }
        return dateArray;
    };

    const getHoursAndMinutes = (date) => {
        return date.toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    const statusMapper = (status, runsCount, stage, monitorIcon = false) => {
        const statusIcon = getStatusIcon(status);

        return (
            <div className="status-icons-container">
                <div className="status-row">
                    {monitorIcon && (
                        <div><FontAwesomeIcon size="2x" color="orange" icon={faBinoculars} /></div>
                    )}
                    {statusIcon && <div>{statusIcon}</div>}
                    <div className="stage-info">
                        {stage}
                    </div>
                </div>
                {runsCount !== 0 && (
                    <div className="status-row">
                        <div className="status-icons"><strong>Runs: </strong> <div className="badge">{runsCount}</div></div>
                    </div>
                )}
            </div>
        );
    }

    const getStatusIcon = (status) => {
        const statusData = IconMap.current[status];
        if (statusData) {
            return <FontAwesomeIcon size="2x" color={statusData.color} icon={statusData.icon} />;
        } else {
            return "Unknown Status Icon";
        }
    };

    const getServiceLogName = (cubeName) => {
        return <a href={`/PipelineStatus?tab=evoJobsDetail&search=${cubeName}&${SearchParams.current.START_DATE}=${formatDateAsDashString(startDate)}&${SearchParams.current.END_DATE}=${formatDateAsDashString(endDate)}`}>{cubeName}</a>;
    }

    const renderRow = (row) => {
        return (
            <tr key={uuid()} className='HoverRow'>
                {columns.map((column) => {
                    let rowValue;
                    let statusDateMap = row[column[0]];
                    let dates = Object.keys(statusDateMap ?? {});

                    if (dates.length === 0) {
                        rowValue = "-";
                    }
                    else if (column[0] === ColumnsNames.current.OVERALL || column[0] === ColumnsNames.current.CUBE_NAME) {
                        rowValue = statusDateMap[1]
                    }
                    else if (dates.length === 1) {
                        rowValue = calculateStatusBasedPreviousRuns(statusDateMap[dates[0]].status)
                    }
                    else {
                        rowValue = <SummaryDropdown
                            summary={summarizeStatus(Object.values(statusDateMap))}
                            contentList={dates.map((date) => calculateStatusBasedPreviousRuns(statusDateMap[date].status, date))}
                        ></SummaryDropdown>
                    }
                    return (
                        <td key={uuid()}>{rowValue}</td>
                    )
                })}
            </tr>
        )
    }

    const calculateStatusBasedPreviousRuns = (statusList, dateRange = null) => {
        let status = null;
        if (statusList.length === 1) {
            status = statusMapper(statusList[0].stageStatus, 1, statusList[0].stage);
        }
        else {
            const currentStatus = statusList[0];
            const previousStatus = statusList[1];
            const showMonitorIcon = (currentStatus.stageStatus === JobStatus.current.IN_PROGRESS
                || currentStatus.stageStatus === JobStatus.current.NEW)
                && (previousStatus.stageStatus === JobStatus.current.NON_RETRIABLE_ERROR
                    || previousStatus.stageStatus === JobStatus.current.RETRIABLE_ERROR
                    || previousStatus.stageStatus === JobStatus.current.CANCELLED);

            status = statusMapper(currentStatus.stageStatus, statusList.length, currentStatus.stage, showMonitorIcon);
        }

        if (dateRange !== null) {
            status = statusWithDateRange(status, dateRange);
        }

        return status;
    }

    const statusWithDateRange = (status, dateRange) => {
        const [from, to] = dateRange?.split("_") || [];
        return (
            <div className="status-range-container">
                <p>{from} - {to}</p>
                {status}
            </div>
        );
    };

    const summarizeStatus = (logJobStatuses) => {
        // using the last run status to summarize the status 
        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.SCHEDULED)) {
            return getStatusIcon(JobStatus.current.SCHEDULED);
        }

        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.NON_RETRIABLE_ERROR)) {
            return getStatusIcon(JobStatus.current.NON_RETRIABLE_ERROR);
        }

        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.RETRIABLE_ERROR)) {
            return getStatusIcon(JobStatus.current.RETRIABLE_ERROR);
        }

        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.CANCELLED)) {
            return getStatusIcon(JobStatus.current.CANCELLED);
        }

        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.IN_PROGRESS || s[0].stageStatus === JobStatus.current.NEW)) {
            return getStatusIcon(JobStatus.current.IN_PROGRESS);
        }

        if (logJobStatuses.some(s => s.status[0].stageStatus === JobStatus.current.SUCCESS)) {
            return getStatusIcon(JobStatus.current.SUCCESS);
        }
    }

    const onStartDateChange = (date) => {
        setStartDate(date);
    };

    const onEndDateChange = (date) => {
        setEndDate(date);
    };

    const StatusIconsHelpOverlay = () => {
        return (<Overlay target={target.current} show={show} placement="left">
            {({
                placement: _placement,
                arrowProps: _arrowProps,
                show: _show,
                popper: _popper,
                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                ...props
            }) => (
                <div
                    {...props}
                    style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        padding: '8px 12px',
                        color: 'black',
                        borderRadius: '4px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
                        ...props.style,
                    }}
                >
                    <div className="status-icon-container">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button variant="light" size="sm" onClick={() => setShow(false)} style={{ marginLeft: 'auto' }}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>
                        <table className="status-icon-table">
                            <tbody>
                                <tr >
                                    <td colSpan="2"> Job Status Icons</td>
                                </tr>
                                {Object.keys(JobStatus.current).map((statusKey) => {
                                    const status = JobStatus.current[statusKey];
                                    const { icon, color } = IconMap.current[status];
                                    const definition = StatusDefinitionMap.current[status];
                                    if (!icon || !color || !definition) return null;
                                    return (
                                        <tr key={status}>
                                            <td className="status-icon-table-row">
                                                <FontAwesomeIcon size="2x" color={color} icon={icon} />
                                            </td>
                                            <td className="status-icon-table-row">{definition}</td>
                                        </tr>
                                    );
                                })}

                                <tr >
                                    <td colSpan="2"> Overall Status Icons</td>
                                </tr>
                                {Object.keys(OverallStatus.current).map((statusKey) => {
                                    const status = OverallStatus.current[statusKey];
                                    const { icon, color } = IconMap.current[status];
                                    const definition = StatusDefinitionMap.current[status];
                                    if (!icon || !color || !definition) return null;
                                    return (
                                        <tr key={status}>
                                            <td className="status-icon-table-row">
                                                <FontAwesomeIcon size="2x" color={color} icon={icon} />
                                            </td>
                                            <td className="status-icon-table-row">{definition}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </Overlay>
        )
    };

    const filterData = () => {
        let params = new URLSearchParams(history.location.search);
        const tab = params.get(SearchParams.current.TAB);
        history.replace({
            search: `${SearchParams.current.TAB}=${tab}&${SearchParams.current.START_DATE}=${formatDateAsDashString(startDate)}&${SearchParams.current.END_DATE}=${formatDateAsDashString(endDate)}`,
        })

        fetchData();
    }

    const handleFilterChange = (key, filter) => {
        setOverAllFilter(prevState => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [filter]: !prevState[key][filter]
            }
        }));
    };

    return (
        <Container fluid className="evo-monitor">
            {error && <Row>
                <Alert variant="danger" className="error">
                    {error}
                </Alert>
            </Row>}
            <Row className="date-range-refresh align-items-center">
                <DateRangePicker
                    onStartDateChange={onStartDateChange}
                    onEndDateChange={onEndDateChange}
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                />
                <Button variant="outline-primary" onClick={filterData} disabled={isPageLoading || (startDate === null && endDate === null)} className="refresh-btn">
                    <FontAwesomeIcon icon={faFilter} />
                </Button>
                <div className="ml-auto">
                    <Button variant="info" ref={target} onClick={() => setShow(!show)}>
                        <FontAwesomeIcon icon={faInfo} />
                    </Button>
                    {StatusIconsHelpOverlay()}
                </div>
            </Row>
            <Row>
            </Row>
            <PaginatedTable
                data={filteredAllData}
                tableColumns={columns}
                isLoading={isPageLoading}
                renderRowContent={renderRow}
                enableRowsPerPage
                filterColumn={overAllFilter}
                filterOnChange={handleFilterChange}
            />
        </Container>
    );
}

export default EvoJobSummary;