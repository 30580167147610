import './PipelineStatusPage.css'

import React, { Component } from 'react';
import { SortOrderEnum, compareString, filterObject, getNextSortOrder, renderSortMark } from '../../Functions/TableHelper'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import EvoDataService from '../../Dataservices/EvoDataService';
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Form from 'react-bootstrap/Form'
import { LoadingGif } from '../PublicComponents/HelperComponents';
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import {formatTime} from '../../Functions/DateTimeHelper';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRedo );

class EvoQuarantinedTask extends Component {

    constructor(props) {
        super(props);

        this.data = [] 

        this.statusObjectKeys = {
            None: 'None',
            TaskId: 'taskId',
            QueuedTask: 'queuedTask',
            QueueName: 'queueName',
            QuarantinedTime: 'quarantinedTime',
            Reason: 'reason',
            Released: 'released',
            ReleaseTime: 'releaseTime',
            Skipped: 'skipped',
            SkippedReason: 'skippedReason',
            SkippedTime: 'skippedTime',
            NumberOfQuarantined: 'numberOfQuarantined',
        }

        this.tableColumns = [
            ["Task Id", this.statusObjectKeys.TaskId],
            ["Task", this.statusObjectKeys.QueuedTask],
            ["Queue Name", this.statusObjectKeys.QueueName],
            ["Quarantined Time", this.statusObjectKeys.QuarantinedTime],
            ["Reason", this.statusObjectKeys.Reason],
            ["Released", this.statusObjectKeys.Released],
            ["ReleaseTime", this.statusObjectKeys.ReleaseTime],
            ["Skipped", this.statusObjectKeys.Skipped],
            ["Skipped Reason", this.statusObjectKeys.SkippedReason],
            ["Skipped Time", this.statusObjectKeys.SkippedTime],
            ["Quarantined Times", this.statusObjectKeys.NumberOfQuarantined]
        ]

        this.state = {
            tableSort: {
                key: this.statusObjectKeys.None,
                order: this.statusObjectKeys.None
            },
            selectedID: null,
            isLoading: true,
            error: null,
            updateTime: null,
            showJson: null
        }
    }

    componentDidMount() {
        EvoDataService.getQuarantinedTasks().then(res => {
            this.updateFromRawdata(res.data)
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })
    }

    updateFromRawdata = (table) => {
        let data = table;
        for (let i in data) {
            data[i].DefaultKey = i;
        }

        this.data = data;
        this.setState({
            showJson: null,
            selectedID: null,
            searchText: '',
            table: data,
            isLoading: false,
            updateTime: new Date()
        })
    }

    compareObject = (key, isAscending) => {
        return (obj1, obj2) => {
            if (key === this.statusObjectKeys.QuarantinedTime || key === this.statusObjectKeys.ReleaseTime) {
                // Convert the date strings to Date objects and get the time in milliseconds
                const time1 = new Date(obj1[key]).getTime();
                const time2 = new Date(obj2[key]).getTime();

                // Compare the times
                if (time1 > time2) {
                    return isAscending ? 1 : -1;
                } else if (time1 < time2) {
                    return isAscending ? -1 : 1;
                } else {
                    return 0;
                }
            }
            else if (key === this.statusObjectKeys.NumberOfQuarantined) {
                // Compare integers
                if (obj1[key] > obj2[key]) {
                    return 1;
                } else if (obj1[key] < obj2[key]) {
                    return -1;
                } else {
                    return 0;
                }
            }
            else {
                // compare strings
                return compareString(obj1[key], obj2[key], isAscending)
            }
        }
    }

    sortList = (list, key, order) => {
        if (order === SortOrderEnum.None) {
            return list.sort(this.compareObject("DefaultKey", true))
        } else {
            return list.sort(this.compareObject(key, order === SortOrderEnum.Ascending))
        }
    }

    onChangeSearchText = e => {
        this.setState({
            searchText: e.target.value
        }, this.onSearch)
    }

    onSearch = () => {
        const {searchText} = this.state;
        let table = [];
        if (searchText.trim() === '') {
            table = this.data;
        } else {
            table = this.data.filter(x => filterObject(x, searchText, []))
        }
        this.setState({
            table: table,
        })
    }

    onSort = (key) => {
        let { tableSort, table }  = this.state
        let nextOrder = getNextSortOrder(tableSort.order, tableSort.key !== key)
    
        tableSort = {
            key: key,
            order: nextOrder
        };
        
        let sortedList = this.sortList(table, key, nextOrder)
        this.setState({
            table: sortedList,
            tableSort: tableSort
        })
    }

    refresh = () => {
        this.setState({isLoading: true});
        EvoDataService.getQuarantinedTasks().then(res => {
            this.updateFromRawdata(res.data)
            this.setState({
                error: null
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                error: err.message
            })
        })
        document.getElementById('evo-quarantine-tasks-refresh').innerHTML = "Evo quarantined tasks refreshed " + (this.state.isLoading ? "loading" : "loaded");
    }

    onRowClick = item => {
        // Parse the queuedTask string into an object
        let queuedTaskObject = JSON.parse(item[this.statusObjectKeys.QueuedTask]);

        // Format the queuedTask object as a string with indentation
        let queuedTaskString = JSON.stringify(queuedTaskObject, null, 2);

        //this.setState({ selectedID: item.DefaultKey, showJson: item })
        this.setState({ selectedID: item.DefaultKey, showJson: item, queuedTaskString: queuedTaskString })
    }

    handleSkipTask = (taskId) => {
        const skipReason = prompt("Please enter the skip reason:");
        if (skipReason) {
            EvoDataService.skipTask(taskId, skipReason)
                .then(() => this.refresh())
                .catch(error => this.setState({ error: error.message }));
        }
    }

    handleReleaseTask = (taskId, queueName) => {
        EvoDataService.releaseTask(taskId, queueName)
            .then(() => this.refresh())
            .catch(error => this.setState({ error: error.message }));
    }

    renderTable() {
        let { tableSort, table, selectedID } = this.state
        let columns = this.tableColumns;
        return (        
            <Row>
                <Col className="Label">
                    <div style={{ overflowX: 'auto', overflowY: 'auto', width: '100%' }} className='QuarantinedTaskTable' aria-live="polite" aria-atomic="true" aria-label={(table ? table.length + " results found" : "")}>
                        <Table striped bordered hover className="tasksTable">
                            <thead>
                                <tr>
                                    {
                                        columns.map(item=>{
                                            let friendly_name = item[0];
                                            let key_name = item[1];
                                            return (
                                                <th key={key_name} onClick={this.onSort.bind(this, key_name)} className="tableHeader" scope="col">
                                                    {renderSortMark(tableSort.key, tableSort.order, key_name)}
                                                    {friendly_name}
                                                </th>
                                            )
                                        })
                                    }
                                    <th scope="col">Action</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {table && table.map(status => (
                                <tr key={status.DefaultKey} className={status.DefaultKey === selectedID ? 'SelectedRow' : 'HoverRow'}
                                    onClick={()=>this.onRowClick(status)}>
                                    {
                                        columns.map(item => {
                                            let key_name = item[1];
                                            let value = status[key_name];
                                            if (key_name === this.statusObjectKeys.Released || key_name === this.statusObjectKeys.Skipped) {
                                                value = value === true ? 'true' : 'false';
                                            }
                                            return (
                                                <td key={key_name}>{value}</td>
                                            )
                                        })
                                    }
                                    <td>
                                        <Button
                                            onClick={this.handleReleaseTask.bind(this, status[this.statusObjectKeys.TaskId], status[this.statusObjectKeys.QueueName])}
                                            disabled={status[this.statusObjectKeys.Released] === true}
                                        >
                                            Release Task
                                        </Button>
                                    </td>
                                    <td>
                                        <Button onClick={this.handleSkipTask.bind(this, status[this.statusObjectKeys.TaskId])}
                                            disabled={status[this.statusObjectKeys.Skipped] === true || status[this.statusObjectKeys.Released] === true}
                                        >
                                            Skip Task
                                        </Button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        )
    }

    render() {
        let {showJson, error, isLoading, updateTime} = this.state;
        let json = null;
        if (showJson) {
            json = JSON.stringify(showJson, null, 2);
        }
        return (
            <div>
                {error && <Alert variant='danger'>{error}</Alert>}
                <Row>
                    <Col className="Label">
                        <div style={{display:"flex",alignItems: "center", marginTop: 10}}>
                            <Button onClick={this.refresh}>
                                { isLoading && <span><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&ensp;</span> }
                                Refresh&ensp;
                                <FontAwesomeIcon icon={faRedo}/>
                            </Button>
                            <div id="evo-quarantine-tasks-refresh" role="status" class="sr-only"></div>
                            <span style={{ paddingLeft: 15 }} role="status">
                                Update time: {formatTime(updateTime)}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <Form.Group className="Label" controlId="quarantinedTaskSearch">
                                <Form.Control style={{marginTop: 20}} type="text" placeholder="Search" onChange={this.onChangeSearchText}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {
                    isLoading ? <LoadingGif></LoadingGif> : this.renderTable()
                }
                {
                    this.state.queuedTaskString &&
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <pre style={{ textAlign: 'left', border: '1px solid gray', minHeight: 20 }}>
                                {
                                    this.state.queuedTaskString
                                }
                            </pre>
                        </Col>
                    </Row>
                }
                {
                    json &&
                    <Row style={{marginTop: 20}}>
                        <Col>
                            <pre style={{textAlign: 'left', border: '1px solid gray', minHeight: 20}}>
                            {
                                json
                            }
                            </pre>
                        </Col>
                    </Row>
                }   
            </div>
        )
    }
}

export default EvoQuarantinedTask;
