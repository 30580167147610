import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './DateRangePicker.css';

const DateRangePicker = ({ initialStartDate, initialEndDate, onStartDateChange, onEndDateChange }) => {  

    const onChange = (dates) => {
        const [start, end] = dates;
        onStartDateChange(start);
        onEndDateChange(end);
      };

    return (
        <Row className="date-range-picker">
            <div className="date-label">Dates</div>
            <DatePicker
                className="date-picker"
                placeholderText="Start Date"
                onChange={onChange}
                startDate={initialStartDate}
                endDate={initialEndDate}
                selectsRange
                isClearable
            />
        </Row>
    );
};

export default DateRangePicker;
