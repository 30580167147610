import { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, OverlayTrigger, Col, Button, Popover, Modal, Alert, Spinner, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faRedo, faCopy, faBan, faBug, faExclamationCircle, faInfoCircle, faPlayCircle, faFilter } from '@fortawesome/free-solid-svg-icons';
import EvoDataService from "../../Dataservices/EvoDataService";
import './EvoJobStatus.css';
import { addDaysToDate, formatDateAsDashString, getPlainDateFromDashStringDate } from "../../Functions/DateTimeHelper";
import { LoadingGif } from "../PublicComponents/HelperComponents";
import { getErrorMessage } from "../../Functions/CommonHelper";
import DateRangePicker from '../PublicComponents/DateRangePicker'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom';

function EvoJobDetail() {
    const pipelineStages = useRef({
        'dataAvailability': 'Data Availability',
        'aggregation': 'Aggregation',
        'shardProcessing': 'Shard Processing',
        'shardIngestion': 'Shard Ingestion'
    });

    const SearchParams = useRef({
        START_DATE: "startDate",
        END_DATE: "endDate",
        SEARCH: "search",
        TAB: "tab"
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [cubes, setCubes] = useState([]);
    const [filteredCubes, setFilteredCubes] = useState([]);
    const [cubeToScheduledJobMap, setCubeToScheduledJobMap] = useState({});
    const [cubeToSheduledTillMap, setCubeToSheduledTillMap] = useState({});
    const [cubeToLoadingScheduledMap, setCubeToLoadingScheduledMap] = useState({});

    const [scheduledJobToJobRunsMap, setScheduledJobToJobRunsMap] = useState({});
    const [scheduledToExpandedMap, setScheduledToExpandedMap] = useState({});
    const [scheduledToIsRerunningMap, setScheduledToIsRerunningMap] = useState({});
    const [scheduledToIsCancellingMap, setScheduledToIsCancellingMap] = useState({});
    const [scheduledToIsRefreshingMap, setScheduledToIsRefreshingMap] = useState({});

    const [debugModalShow, setDebugModalShow] = useState(false);
    const [debugInfo, setDebugInfo] = useState({});

    const [jobStatisticsModalShow, setJobStatisticsModalShow] = useState(false);
    const [jobStatistics, setJobStatistics] = useState({});
    const [jobStatisticsAvailable, setJobStatisticsAvailable] = useState(false);

    const [error, setError] = useState('');
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [searchText, setSearchText] = useState('');

    const history = useHistory();

    useEffect(() => {
        let params = new URLSearchParams(history.location.search);

        const startDateValue = params.get(SearchParams.current.START_DATE);
        const endDateValue = params.get(SearchParams.current.END_DATE);
        const searchTextValue = params.get(SearchParams.current.SEARCH);

        setSearchText(searchTextValue);

        if (startDateValue && endDateValue && new Date(startDateValue) < new Date(endDateValue)) {
            const formatedStartDate = getPlainDateFromDashStringDate(startDateValue);
            const formatedEndDate = getPlainDateFromDashStringDate(endDateValue);
            setStartDate(new Date(formatedStartDate));
            setEndDate(new Date(formatedEndDate));
        } else {
            setStartDate(addDaysToDate(new Date(), -7));
            setEndDate(new Date());
        }

    }, []);

    useEffect(() => {
        Object.keys(cubeToScheduledJobMap).forEach(cubeId => {
            let scheduledJobs = cubeToScheduledJobMap[cubeId];

            if (scheduledJobs.length !== 0) {
                let maxDate = scheduledJobs.reduce((max, job) => {
                    return max < job.fromDateTime ? job.fromDateTime : max;
                }, scheduledJobs[0].fromDateTime);

                setCubeToSheduledTillMap({ ...cubeToSheduledTillMap, [cubeId]: maxDate })
            }

        });
    }, [cubeToScheduledJobMap]);

    useEffect(() => {
        if (isPageLoading && startDate && endDate) {

            EvoDataService.getActiveCubes()
                .then(res => {
                    const tempCubes = res.data.map(cube => {
                        // Add this so that frontend doesn't need to change too much if the object keys returned from backend change
                        return {
                            cubeId: cube.cubeId,
                            cubeName: cube.cubeName,
                            status: cube.status,
                            mainServiceId: cube.mainServiceId,
                            mainServiceName: cube.mainServiceName,
                            mainServiceLogId: cube.mainServiceLogId,
                            mainServiceLogName: cube.mainServiceLogName,
                            availableFrom: cube.availableFrom
                        }
                    });
                    setCubes(tempCubes);
                    setCubeToLoadingScheduledMap(res.data.reduce((prev, curr) => {
                        prev[curr.cubeId] = true;
                        return prev;
                    }, {}));

                })
                .catch(error => {
                    console.error(getErrorMessage(error, true));
                    setError(getErrorMessage(error));
                })
                .finally(() => {
                    setIsPageLoading(false);
                });
        }
    }, [isPageLoading, startDate, endDate]);

    useEffect(() => {
        cubes.forEach(cube => {
            getMonitoredScheduledJobs(cube.cubeId);
        });
        setFilteredCubes(filterCubes(searchText, cubes));
    }, [cubes]);

    useEffect(() => {
        setFilteredCubes(filterCubes(searchText, cubes));
    }, [searchText])

    const filterCubes = (searchText, cubes) => {
        if (!searchText || searchText === "") return cubes;
        return cubes.filter(cube => {
            return cube.cubeName.toLowerCase().includes(searchText.toLowerCase());
        });
    }

    const getMonitoredScheduledJobs = (cubeId) => {
        cubeToScheduledJobMap[cubeId] = [];

        cubeToLoadingScheduledMap[cubeId] = true;
        setCubeToLoadingScheduledMap({ ...cubeToLoadingScheduledMap });
        return EvoDataService.getMonitoredScheduledJobs(formatDateAsDashString(startDate), formatDateAsDashString(endDate), cubeId)
            .then(res => {
                res.data.forEach(scheduledJob => {
                    cubeToScheduledJobMap[cubeId].push({
                        scheduledJobId: scheduledJob.scheduledJobId,
                        fromDateTime: scheduledJob.from,
                        toDateTime: scheduledJob.to,
                        processAfterDate: scheduledJob.processAfterDate,
                        processByDate: scheduledJob.processByDate,
                        scheduleIteration: scheduledJob.scheduleIteration,
                        status: scheduledJob.status
                    });

                    scheduledToExpandedMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsCancellingMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsRerunningMap[scheduledJob.scheduledJobId] = false;
                    scheduledToIsRefreshingMap[scheduledJob.scheduledJobId] = false;

                    setCubeToScheduledJobMap({ ...cubeToScheduledJobMap });

                    getCubeJobRunsByScheduledJobId(scheduledJob.scheduledJobId, false);

                    setScheduledToExpandedMap({ ...scheduledToExpandedMap });
                    setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap });
                    setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap });
                    setScheduledToIsRefreshingMap({ ...scheduledToIsRefreshingMap })
                });
                cubeToLoadingScheduledMap[cubeId] = false;
                setCubeToLoadingScheduledMap({ ...cubeToLoadingScheduledMap });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    }

    const refreshPage = () => {
        let params = new URLSearchParams(history.location.search);
        const tab = params.get(SearchParams.current.TAB);
        history.replace({
            search: `${SearchParams.current.TAB}=${tab}&${SearchParams.current.START_DATE}=${formatDateAsDashString(startDate)}&${SearchParams.current.END_DATE}=${formatDateAsDashString(endDate)}`,
        })

        setError('');
        setSearchText('');
        setIsPageLoading(true);
        setCubes([]);
        setCubeToScheduledJobMap({});
        setCubeToSheduledTillMap({});
        setCubeToLoadingScheduledMap({});
        setScheduledToExpandedMap({});
        setScheduledToIsRerunningMap({});
        setScheduledToIsCancellingMap({});
        setScheduledToIsRefreshingMap({});
        setScheduledJobToJobRunsMap({});
    };

    const refreshLog = (cubeId) => {
        getMonitoredScheduledJobs(cubeId);
    }

    const refreshJob = (cubeId, scheduledJobId) => {
        setScheduledToIsRefreshingMap({ ...scheduledToIsRefreshingMap, [scheduledJobId]: true });

        EvoDataService.getMonitoredScheduledJobs(formatDateAsDashString(startDate), formatDateAsDashString(endDate), cubeId)
            .then(res => {
                let jobIndex = cubeToScheduledJobMap[cubeId].findIndex(x => x.scheduledJobId === scheduledJobId)
                cubeToScheduledJobMap[cubeId][jobIndex].status = res.data[jobIndex].status;
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });

        getCubeJobRunsByScheduledJobId(scheduledJobId, true)
            .finally(() => {
                setScheduledToIsRefreshingMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            });
    }

    const getCubeJobRunsByScheduledJobId = (scheduledJobId, expandByDefault) => {
        return EvoDataService.getCubeJobRunsByScheduledJobId(scheduledJobId)
            .then(res => {
                scheduledJobToJobRunsMap[scheduledJobId] = res.data;
                scheduledToExpandedMap[scheduledJobId] = expandByDefault;
                setScheduledJobToJobRunsMap({ ...scheduledJobToJobRunsMap });
                setScheduledToExpandedMap({ ...scheduledToExpandedMap });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const onExpandScheduledJobs = (scheduledJobId) => {
        scheduledToExpandedMap[scheduledJobId] = !scheduledToExpandedMap[scheduledJobId];
        setScheduledToExpandedMap({ ...scheduledToExpandedMap });
    };

    const onExpandSerivceLogJobs = (scheduledJobId, cubeJobRunId) => {
        let index = scheduledJobToJobRunsMap[scheduledJobId].findIndex(x => x.cubeJobRunId === cubeJobRunId)
        scheduledJobToJobRunsMap[scheduledJobId][index].isExpanded = !scheduledJobToJobRunsMap[scheduledJobId][index].isExpanded;
        setScheduledJobToJobRunsMap({ ...scheduledJobToJobRunsMap });
    };

    const toggleDebugModal = () => {
        setDebugModalShow(!debugModalShow);
    };

    const toggleJobStatisticsModal = () => {
        setJobStatisticsModalShow(!jobStatisticsModalShow);
    };

    const getLogProperty = (cubeJobRunId) => {
        setError('');
        EvoDataService.getLogPropertyForMonitoring(cubeJobRunId)
            .then(res => {
                setDebugInfo(Object.keys(res.data).reduce((pre, curr) => {
                    pre[curr] = JSON.stringify(res.data[curr], null, 4);
                    return pre;
                }, {}));
                toggleDebugModal();
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const debugPipelineStage = (stageRunId, stage) => {
        let debugFunc;
        switch (stage) {
            case 'dataAvailability':
                debugFunc = EvoDataService.getDataAvailabilityDiagnostic;
                break;
            case 'aggregation':
                debugFunc = EvoDataService.getAggregationDiagnostic;
                break;
            case 'shardProcessing':
                debugFunc = EvoDataService.getShardProcessingDiagnostic;
                break;
            case 'shardIngestion':
                debugFunc = EvoDataService.getShardIngestionDiagnostic;
                break;
            default:
                break;
        }

        setError('');
        debugFunc(stageRunId)
            .then(res => {
                setDebugInfo(res.data);
                toggleDebugModal();
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
            });
    };

    const getJobStatistics = (jobGuid) => {
        setError('');

        EvoDataService.getJobStatistics(jobGuid).then(
            res => {
                setJobStatistics(res.data);
                toggleJobStatisticsModal();
                setJobStatisticsAvailable(true);
            }
        ).catch(error => {
            setJobStatistics({ "Error Message:": "There are no job statistics available for this job. Error Message: " + getErrorMessage(error, true) })
            toggleJobStatisticsModal();
            setJobStatisticsAvailable(false);
        });
    };

    const onStartDateChange = (date) => {
        setStartDate(date);
    };

    const onEndDateChange = (date) => {
        setEndDate(date);
    };

    const onCopy = (content) => {
        navigator.clipboard.writeText(content);
    };

    const getColor = (status) => {
        switch (status) {
            case 'Success':
            case 'Completed':
                return '#28a745';
            case 'Failure':
            case 'NonRetriableError':
                return '#dc3545';
            case 'Running':
            case 'InProgress':
            case 'Processing':
                return '#2684ff';
            case 'RetriableError':
            case 'Cancelled':
                return '#ffc107';
            default:
                return 'black';
        }
    };

    const onRerunJob = (scheduledJobId, cubeId) => {
        let jobIndex = cubeToScheduledJobMap[cubeId].findIndex(x => x.scheduledJobId === scheduledJobId)
        setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: true });
        EvoDataService.rerunJob(scheduledJobId)
            .then(() => {
                cubeToScheduledJobMap[cubeId][jobIndex].status = 'Processing';
                setCubeToScheduledJobMap({ ...cubeToScheduledJobMap });
                getCubeJobRunsByScheduledJobId(scheduledJobId, true);
                setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setScheduledToIsRerunningMap({ ...scheduledToIsRerunningMap, [scheduledJobId]: false });
            });
    };

    const onCancelJob = (scheduledJobId, cubeId) => {
        let jobIndex = cubeToScheduledJobMap[cubeId].findIndex(x => x.scheduledJobId === scheduledJobId)
        setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: true });
        EvoDataService.cancelJob(scheduledJobId)
            .then(() => {
                cubeToScheduledJobMap[cubeId][jobIndex].status = 'Completed';
                setCubeToScheduledJobMap({ ...cubeToScheduledJobMap });
                getCubeJobRunsByScheduledJobId(scheduledJobId, true);
                setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: false });
            })
            .catch(error => {
                console.error(getErrorMessage(error, true));
                setError(getErrorMessage(error));
                setScheduledToIsCancellingMap({ ...scheduledToIsCancellingMap, [scheduledJobId]: false });
            })
    };

    const onChangeSearchText = searchText => {
        setSearchText(searchText);
    }

    const calculateTimeDifference = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return 'Job Duration Unavailable';
        }
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        if (startDate > endDate) {
            return 'Start time is later than end time';
        }

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDate - startDate;

        // Ensure the difference is positive
        const absoluteTimeDifferenceMs = Math.abs(timeDifferenceMs);

        // Convert milliseconds to seconds, minutes, hours, and days
        const seconds = Math.floor(absoluteTimeDifferenceMs / 1000) % 60;
        const minutes = Math.floor(absoluteTimeDifferenceMs / (1000 * 60)) % 60;
        const hours = Math.floor(absoluteTimeDifferenceMs / (1000 * 60 * 60)) % 24;
        const days = Math.floor(absoluteTimeDifferenceMs / (1000 * 60 * 60 * 24));

        // Construct the human-readable string
        let result = '';

        if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
        if (hours > 0 || days > 0) result += `${hours} hour${hours > 1 ? 's' : ''} `;
        if (minutes > 0 || hours > 0 || days > 0) result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
        result += `${seconds} second${seconds > 1 ? 's' : ''}`;

        return result.trim();
    }

    const calculateConsumedAUs = (startTime, endTime, au) => {
        if (!startTime || !endTime || !au) {
            return '';
        }
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        if (startDate > endDate) {
            return 'Start time is later than end time';
        }

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDate - startDate;

        // Ensure the difference is positive
        const absoluteTimeDifferenceMs = Math.abs(timeDifferenceMs);
        var value = (absoluteTimeDifferenceMs / 3600000) * au;

        //Only get three decimal places
        return Math.trunc(1000 * value) / 1000;
    }

    const renderPipelineStage = (stageInfo, stage) => {
        return (
            <Row className="pipeline-stage-row" key={stageInfo.stageRunId}>
                <Col className="property-cell"></Col>
                <Col className="property-cell">
                    <span>Stage Name</span>
                    {pipelineStages.current[stage]}
                </Col>
                <Col className="property-cell">
                    <span>Status</span>
                    <div style={{ color: getColor(stageInfo.status) }}>{stageInfo.status}</div>
                </Col>
                <Col className="property-cell">
                    <span>Stage Start On</span>
                    {stageInfo.submittedOn}
                </Col>
                <Col className="property-cell">
                    <span>Stage End On</span>
                    {stageInfo.endOn}
                </Col>
                <Col className="property-cell">
                    <span>Error Count</span>
                    {stageInfo.errorCount}
                </Col>
                <Col className="property-cell">
                    {stageInfo.errorMessage && <OverlayTrigger
                        trigger="click"
                        placement="left"
                        overlay={
                            <Popover>
                                <Popover.Title as="div">
                                    <Button variant="outline-secondary" onClick={onCopy.bind(this, stageInfo.errorMessage)}>
                                        <FontAwesomeIcon icon={faCopy} /> &ensp;Copy to clipboard
                                    </Button>
                                </Popover.Title>
                                <Popover.Content style={{ maxHeight: 500, overflowY: 'auto' }}>
                                    {stageInfo.errorMessage}
                                </Popover.Content>
                            </Popover>
                        }>
                        <Button variant="outline-primary"><FontAwesomeIcon icon={faExclamationCircle} />&ensp;Show Error Message</Button>
                    </OverlayTrigger>}
                </Col>
                <Col className="property-cell, btn-cell">
                    <Button variant="outline-primary" onClick={debugPipelineStage.bind(this, stageInfo.stageRunId, stage)}>
                        <FontAwesomeIcon icon={faBug} />&ensp;
                        Debug
                    </Button>
                    {stageInfo.jobGuid &&
                        <Button variant="outline-primary" onClick={getJobStatistics.bind(this, stageInfo.jobGuid)}>
                            <FontAwesomeIcon icon={faInfoCircle} />&ensp;
                            Analysis
                        </Button>}
                </Col>
            </Row>
        );
    };

    return (
        <Container fluid className="evo-job-status">
            {error && <Row>
                <Alert variant="danger" className="error">
                    {error}
                </Alert>
            </Row>}
            <Row className="date-range-refresh  align-items-center">
                <DateRangePicker
                    onStartDateChange={onStartDateChange}
                    onEndDateChange={onEndDateChange}
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                />
                <Button variant="outline-primary" onClick={refreshPage} disabled={isPageLoading || startDate === null && endDate === null}><FontAwesomeIcon icon={faFilter} /></Button>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3" style={{ marginTop: 20 }}>
                        <Form.Control type="text" placeholder="Search" onChange={e => onChangeSearchText(e.target.value)} value={searchText} />
                        <Button variant="outline-secondary" id="button-addon2" onClick={() => onChangeSearchText("")}>
                            Clear
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            {isPageLoading ? <Row>
                <Col><LoadingGif /></Col>
            </Row>
                : filteredCubes.map((cube) =>
                    <Fragment key={cube.cubeId}>
                        <Row className="cube-name">
                            {cube.cubeName} ({cube.status})
                        </Row>

                        <Row>
                            <Col className="property-cell">
                                <span>Cube Id</span>
                                {cube.cubeId}
                            </Col>
                            <Col className="property-cell">
                                <span>Main Service Id</span>
                                {cube.mainServiceId}
                            </Col>
                            <Col className="property-cell">
                                <span>Main Service Name</span>
                                {cube.mainServiceName}
                            </Col>
                            <Col className="property-cell">
                                <span>Main Service Log Id</span>
                                {cube.mainServiceLogId}
                            </Col>
                            <Col className="property-cell">
                                <span>Main Service Log Name</span>
                                {cube.mainServiceLogName}
                            </Col>
                            <Col className="property-cell">
                                <span>Scheduled Till</span>
                                {cubeToSheduledTillMap[cube.cubeId] || 'No scheduled jobs'}
                            </Col>
                            {/* Add extra Cols here to make the whole page vertically justified */}
                            <Col className="property-cell"></Col>
                            <Col className="property-cell">
                                <Button variant="outline-primary" style={{ width: 100 }}
                                    onClick={() => refreshLog(cube.cubeId)}><FontAwesomeIcon icon={faRedo} />&ensp;Refresh
                                </Button>
                            </Col>
                        </Row>

                        {cubeToLoadingScheduledMap[cube.cubeId] ? <Row className="loading-scheduled-jobs">
                            <Spinner animation="border" role="status" variant="primary" />&emsp;Loading scheduled jobs...
                        </Row>
                            : cubeToScheduledJobMap[cube.cubeId] && cubeToScheduledJobMap[cube.cubeId].length > 0 &&
                            cubeToScheduledJobMap[cube.cubeId].map((scheduledJob) =>
                                <Fragment key={scheduledJob.scheduledJobId}>
                                    <Row className="scheduled-job-row">
                                        <Col className="property-cell-with-expand">
                                            <Button variant="outline-primary" onClick={onExpandScheduledJobs.bind(this, scheduledJob.scheduledJobId)}>
                                                {scheduledToExpandedMap[scheduledJob.scheduledJobId] ? <FontAwesomeIcon icon={faChevronCircleUp} /> : <FontAwesomeIcon icon={faChevronCircleDown} />}
                                            </Button>
                                            <div>
                                                <span>ScheduledJob Id</span>
                                                {scheduledJob.scheduledJobId}
                                            </div>
                                        </Col>
                                        <Col className="property-cell">
                                            <span>From datetime</span>
                                            {scheduledJob.fromDateTime}
                                        </Col>
                                        <Col className="property-cell">
                                            <span>To datetime</span>
                                            {scheduledJob.toDateTime}
                                        </Col>
                                        <Col className="property-cell">
                                            <span>Iteration</span>
                                            {scheduledJob.scheduleIteration}
                                        </Col>
                                        <Col className="property-cell">
                                            <span>Status</span>
                                            <div style={{ color: getColor(scheduledJob.status === 'Completed' ? scheduledJobToJobRunsMap[scheduledJob.scheduledJobId]?.[0]?.jobStatus : scheduledJob.status) }}>
                                                {scheduledJob.status === 'Completed'
                                                    && (!scheduledJobToJobRunsMap[scheduledJob.scheduledJobId]
                                                        || !scheduledJobToJobRunsMap[scheduledJob.scheduledJobId][0])
                                                    ? <Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" />
                                                    : <Fragment>
                                                        {scheduledJob.status === 'Completed'
                                                            ? scheduledJob.status + ' - ' + scheduledJobToJobRunsMap[scheduledJob.scheduledJobId]?.[0]?.jobStatus
                                                            : scheduledJob.status}
                                                    </Fragment>}
                                            </div>
                                        </Col>
                                        <Col className="property-cell">
                                            <span>Process After</span>
                                            {scheduledJob.processAfterDate}
                                        </Col>
                                        <Col className="property-cell">
                                            <span>Process By</span>
                                            {scheduledJob.processByDate}
                                        </Col>
                                        <Col className="btn-cell">
                                            <Button variant="outline-primary" style={{ width: 100 }}
                                                disabled={(scheduledJob.status !== 'Completed' && scheduledJob.status !== 'Scheduled') || scheduledToIsRerunningMap[scheduledJob.scheduledJobId]}
                                                onClick={onRerunJob.bind(this, scheduledJob.scheduledJobId, cube.cubeId)}>
                                                <FontAwesomeIcon icon={faPlayCircle} />&ensp;{scheduledJob.status === 'Completed' ? 'Rerun' : 'Trigger'}
                                                {scheduledToIsRerunningMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                            </Button>
                                            <Button variant="outline-primary" style={{ width: 100 }}
                                                disabled={scheduledJob.status !== 'Processing' || scheduledToIsCancellingMap[scheduledJob.scheduledJobId]}
                                                onClick={onCancelJob.bind(this, scheduledJob.scheduledJobId, cube.cubeId)}>
                                                <FontAwesomeIcon icon={faBan} />&ensp;Cancel
                                                {scheduledToIsCancellingMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                            </Button>
                                            <Button variant="outline-primary" style={{ width: 100 }}
                                                disabled={scheduledToIsRefreshingMap[scheduledJob.scheduledJobId]}
                                                onClick={refreshJob.bind(this, cube.cubeId, scheduledJob.scheduledJobId)}>
                                                <FontAwesomeIcon icon={faRedo} />&ensp;Refresh
                                                {scheduledToIsRefreshingMap[scheduledJob.scheduledJobId] && <Fragment>&ensp;<Spinner as="span" animation="border" size="sm" role="status" ari-hidden="true" /></Fragment>}
                                            </Button>
                                        </Col>
                                    </Row>

                                    {scheduledToExpandedMap[scheduledJob.scheduledJobId] && scheduledJobToJobRunsMap[scheduledJob.scheduledJobId]?.map((jobRun) =>
                                        <Fragment key={jobRun.cubeJobRunId}>
                                            <Row className="servicelog-job-row">
                                                <Col className="property-cell-with-expand">
                                                    <Button variant="outline-primary" onClick={onExpandSerivceLogJobs.bind(this, scheduledJob.scheduledJobId, jobRun.cubeJobRunId)}>
                                                        {jobRun.isExpanded ? <FontAwesomeIcon icon={faChevronCircleUp} /> : <FontAwesomeIcon icon={faChevronCircleDown} />}
                                                    </Button>
                                                    <div>
                                                        <span>ServiceLogJobRun Id</span>
                                                        {jobRun.cubeJobRunId}
                                                    </div>
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Job Stage</span>
                                                    {jobRun.jobStage}
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Status</span>
                                                    <div style={{ color: getColor(jobRun.jobStatus) }}>{jobRun.jobStatus}</div>
                                                </Col>
                                                <Col className="property-cell">
                                                    <span>Created On</span>
                                                    {jobRun.createdOn}
                                                </Col>
                                                <Col className="property-cell">
                                                    <Button variant="outline-primary" onClick={getLogProperty.bind(this, jobRun.cubeJobRunId)}>
                                                        <FontAwesomeIcon icon={faInfoCircle} />&ensp;
                                                        Get log property
                                                    </Button>
                                                </Col>
                                                <Col className="property-cell"></Col>
                                                <Col className="property-cell"></Col>
                                                <Col className="property-cell"></Col>
                                            </Row>

                                            {jobRun.isExpanded && <Fragment>
                                                {jobRun.dataAvailibity && renderPipelineStage(jobRun.dataAvailibity, 'dataAvailability')}
                                                {jobRun.aggregation && renderPipelineStage(jobRun.aggregation, 'aggregation')}
                                                {jobRun.shardProcessing && <Fragment>
                                                    {jobRun.shardProcessing.map((shardProcessing) => renderPipelineStage(shardProcessing, 'shardProcessing'))}
                                                </Fragment>}
                                                {jobRun.shardIngestion && <Fragment>
                                                    {jobRun.shardIngestion.map((shardIngestion) => renderPipelineStage(shardIngestion, 'shardIngestion'))}
                                                </Fragment>}
                                            </Fragment>}
                                        </Fragment>
                                    )}
                                </Fragment>)}
                    </Fragment>
                )}

            {debugModalShow && <Modal show={debugModalShow} onHide={toggleDebugModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Debug information</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Object.keys(debugInfo).map(key => <div className="evo-jobs-debug-info-block" key={key}>
                        <div className="title">
                            {key}
                            <Button variant="outline-secondary" onClick={onCopy.bind(this, debugInfo[key])}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </div>
                        {debugInfo[key]}
                    </div>)}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleDebugModal}>Close</Button>
                </Modal.Footer>
            </Modal>}

            {jobStatisticsModalShow && <Modal show={jobStatisticsModalShow} onHide={toggleJobStatisticsModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Job Statistics</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {jobStatistics && jobStatisticsAvailable &&
                        <div className="evo-jobs-stats-info-block">
                            <Table striped bordered hover class="job-details-table" style={{ width: "60%%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Job GUID</td>
                                        <td>{jobStatistics.jobDetails?.jobGuid}</td>
                                    </tr>
                                    <tr>
                                        <td>Job Name</td>
                                        <td>{jobStatistics.jobDetails?.jobName}</td>
                                    </tr>
                                    <tr>
                                        <td>Submitter</td>
                                        <td>{jobStatistics.jobDetails?.submitter}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Job Details:</h3>
                            <Table striped bordered hover class="job-details-table" style={{ width: "50%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Result</td>
                                        <td>{jobStatistics.jobDetails?.result}</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>{jobStatistics.jobDetails?.state}</td>
                                    </tr>
                                    <tr>
                                        <td>Priority</td>
                                        <td>{jobStatistics.jobDetails?.priority === -1 ? "" : jobStatistics.jobDetails?.priority}</td>
                                    </tr>
                                    <tr>
                                        <td>AUs</td>
                                        <td>{jobStatistics.degreeOfParallelism === -1 ? "" : jobStatistics.degreeOfParallelism}</td>
                                    </tr>
                                    <tr>
                                        <td>Consumed AUs</td>
                                        <td>{calculateConsumedAUs(jobStatistics.startTime, jobStatistics.endTime, jobStatistics.degreeOfParallelism)}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Job Size:</h3>
                            <Table striped bordered hover class="job-details-table" style={{ width: "75%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><strong>Total Row Count</strong></td>
                                        <td><strong>Total Size (Bytes) </strong></td>
                                    </tr>
                                    <tr>
                                        <td>Input</td>
                                        <td>{jobStatistics.totalInputRowCount === -1 ? "" : jobStatistics.totalInputRowCount}</td>
                                        <td>{jobStatistics.totalInputSize === -1 ? "" : jobStatistics.totalInputSize}</td>
                                    </tr>
                                    <tr>
                                        <td>Output</td>
                                        <td>{jobStatistics.totalOutputRowCount === -1 ? "" : jobStatistics.totalOutputRowCount}</td>
                                        <td>{jobStatistics.totalOutputSize === -1 ? "" : jobStatistics.totalOutputSize}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <hr />

                            <h3>Timing:</h3>
                            <p><strong>Submit Time:</strong> {jobStatistics.submitTime}</p>
                            <p><strong>Job Duration:</strong> {calculateTimeDifference(jobStatistics.startTime, jobStatistics.endTime)}</p>
                            <div class="indented">
                                <p><strong>Start Time:</strong> {jobStatistics.startTime}</p>
                                <p><strong>End Time:</strong> {jobStatistics.endTime}</p>
                            </div>

                            <hr />

                            <h3>Job Stages:</h3>
                            <p><strong>Number of Stages:</strong> {jobStatistics.numberofStages}</p>

                            <Table striped bordered hover class="job-details-table" style={{ width: "100%" }}>
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Stage Name</td>
                                        <td>Data Read (bytes)</td>
                                        <td>Data Written (bytes)</td>
                                        <td>Running Count:</td>
                                        <td>Scheduled Count:</td>
                                        <td>Failed Count:</td>
                                        <td>Succeeded Count:</td>
                                    </tr>

                                    {jobStatistics.jobStages?.map((stage, index) => (
                                        <tr key={index}>
                                            <td>{stage.stageName}</td>
                                            <td>{stage.dataRead === -1 ? "" : stage.dataRead}</td>
                                            <td>{stage.dataWritten === -1 ? "" : stage.dataWritten}</td>
                                            <td>{stage.runningCount === -1 ? "" : stage.runningCount}</td>
                                            <td>{stage.scheduledCount === -1 ? "" : stage.scheduledCount}</td>
                                            <td>{stage.failedCount === -1 ? "" : stage.failedCount}</td>
                                            <td>{stage.succeededCount === -1 ? "" : stage.succeededCount}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    }
                    {!jobStatisticsAvailable &&
                        <div>
                            {jobStatistics["Error Message:"]}
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleJobStatisticsModal}>Close</Button>
                </Modal.Footer>
            </Modal>}
        </Container>
    )
};

export default EvoJobDetail;