import React, { useEffect, useState, useRef } from 'react'

import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import { SortOrderEnum, getNextSortOrder, renderSortMark, sortList, getPageNumbersToDisplay } from '../../Functions/TableHelper'
import uuid from 'react-uuid'

/*
    Expected props:
        data: The data to display in array form matching the headers and row arrays passed in
        tableColumns: an array of the header values
        isLoading: bool if we are loading the data

    Optional props:
        tableSize (defaults to 10)
        initialSortCol (defaults to the first one)
*/
export function PaginatedTable(props) {
    const numberOfRowsPerPage = useRef([10, 25, 50, 100]);
    let [displayedRows, setDisplayedRows] = useState([]);
    let [tableData, setTableData] = useState();
    let [tableSort, setTableSort] = useState({});
    let [tableSize, setTableSize] = useState(props.tableSize ? props.tableSize : 10);
    let [currentPage, setCurrentPage] = useState(0);
    let [numberOfPages, setNumberOfPages] = useState(0);
    let [pageNumbers, setPageNumbers] = useState([1]);

    let setup = () => {
        var tableSort;

        if (props.tableSort) {
            tableSort = {
                sortKey: props.tableSort.sortKey,
                sortOrder: props.tableSort.sortOrder
            };
        }
        else {
            tableSort = {
                sortKey: props.tableColumns[0],
                sortOrder: SortOrderEnum.Descending
            };
        }

        // Fill the current pages data
        var numOfPages = Math.ceil(props.data.length / tableSize);
        setTableSort(tableSort);
        setNumberOfPages(numOfPages);
        setCurrentPage(1);
        var tableDataList = sortList(props.data, tableSort.sortKey, tableSort.sortOrder);
        setTableData(tableDataList);
        setPageNumbers(getPageNumbersToDisplay(1, numOfPages));
        setDisplayedRows(tableDataList.slice(0, props.tableSize ? props.tableSize : tableSize));
    }
    useEffect(setup, [props.data, tableSize]);

    let onSort = (key) => {

        let nextOrder = getNextSortOrder(tableSort.sortOrder, key !== tableSort.sortKey, true);

        tableSort = {
            sortKey: key,
            sortOrder: nextOrder
        };

        setTableData(tableData => sortList(tableData, key, nextOrder));
        setTableSort(tableSort);
        setDisplayedRows(tableData.slice((currentPage - 1) * tableSize, (currentPage) * (tableSize)));
    }

    let onChangePage = (page) => {

        if (page > numberOfPages || page <= 0) {
            console.error("Tried to go to a page that does not exist: " + page);
            console.log(props.data);
            return;
        }

        setCurrentPage(page);
        setDisplayedRows(tableData.slice((page - 1) * tableSize, (page) * (tableSize)));
    }

    let renderRow = (row) => {
        if (props.renderRowContent) {
            return props.renderRowContent(row);
        }

        return (
            <tr key={uuid()} className='HoverRow'>
                {props.tableColumns.map((item, index) => {
                    let key_name = row[item[0]];
                    return (
                        <td key={uuid()}>{key_name}</td>
                    )
                })}
            </tr>
        )
    }

    let renderFilterMark = (key) => {
        if (props.filterColumn && props.filterOnChange && props.filterColumn[key]) {
            const filterValues = props.filterColumn[key];
            return <div style={{ display: "inline-flex", marginLeft: "10px" }}>
                <Dropdown  drop={"down"}>
                    <Dropdown.Toggle variant="secondary" style={{ padding : ".035rem .45rem"}}>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Object.keys(filterValues).map((filter) => {
                            return <Form.Check style={{ margin: '10px' }}
                                type={"checkbox"}
                                id={`checkbox-${filter}`}
                                label={filter}
                                checked={filterValues[filter]}
                                onChange={() => { props.filterOnChange(key, filter); }}
                            />
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {props.isLoading ? <img alt="Loading" className="center" src="..\gears.gif" /> :
                <Col>
                    <Row>
                        <Col>
                            <div aria-live="polite" aria-atomic="true" aria-label={(displayedRows ? displayedRows.length + " results found" : "")}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            {
                                                props.tableColumns.map(item => {
                                                    return (
                                                        <th key={item[0]} onClick={() => onSort(item[0])} className="tableHeader" scope="col">
                                                            {renderSortMark(tableSort.key, tableSort.order, item)}
                                                            {item[1]}
                                                            {renderFilterMark(item[0])}
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.data.length > 0 && displayedRows.map(row => renderRow(row))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Pagination>
                                {props.enableRowsPerPage && <Dropdown drop={"up"}>
                                    <Dropdown.Toggle variant="light">
                                        Rows per Page: {tableSize}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {numberOfRowsPerPage.current.map((number) => {
                                            return <Dropdown.Item key={number} onClick={setTableSize.bind(this, number)}>{number}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                }
                                <Pagination.First onClick={() => onChangePage(1)} />
                                <Pagination.Prev onClick={() => onChangePage(currentPage - 1)} />
                                {pageNumbers.map(num => num === 0 ?
                                    <Pagination.Ellipsis disabled key={uuid()} /> :
                                    <Pagination.Item key={uuid()} onClick={() => onChangePage(num)} active={currentPage === num ? true : false}>
                                        {num}
                                    </Pagination.Item>
                                )}
                                <Pagination.Next onClick={() => onChangePage(currentPage + 1)} />
                                <Pagination.Last onClick={() => onChangePage(numberOfPages)} />
                            </Pagination>
                        </Col>
                    </Row>
                </Col>
            }
        </div>
    )
}

export default PaginatedTable