import React, { useState } from 'react';
import { Tabs } from '../../Functions/ChartHelpers'
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import AnnotationService from '../../Dataservices/AnnotationDataService'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faExternalLinkAlt);

export function AnnotationManagementTableRow (props) {
    const { annotation, isAdmin } = props
    const tabObj = Tabs.find(tab => tab.dbName === annotation.tab)
    const tab = tabObj ? tabObj.friendlyName : annotation.tab

    const [isDeleted, setIsDeleted] = useState(annotation.isDeleted)
    const [error, setError] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    const onChangeIsDeleted = e => {
        const isDeleted = e.target.checked
        setIsDeleted(isDeleted)
        let postEditAnnotationRequest = {
            Id: annotation.ID,
            Date: annotation.date,
            AnnotationText: annotation.annotation1,
            IsDeleted: isDeleted
        }

        setIsSaving(true)
        setError('')
        AnnotationService.postEditAnnotationsForChart(postEditAnnotationRequest).then(() => {
            setIsSaving(false)
        }).catch(err => {
            console.error(err);
            setIsDeleted(!isDeleted)
            setIsSaving(false)
            setError('Failed to save: ' + err.message)
        })
    }

    return(
        <tr>
            <td>{annotation.date ? annotation.date.split("T")[0] : ''}</td>
            <td><div>{parse(annotation.annotation1)}</div></td>
            <td>{annotation.cube}</td>
            <td>{tab}</td>
            <td>{annotation.legend}</td>
            <td>{annotation.user}</td>
            <td>{annotation.lastModifiedDate}</td>
            <td>{annotation.lastModifiedBy}</td>
            <td>{annotation.url ? <a className="accessibleLink" aria-label="Link to chart" href={annotation.url} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt}/></a>: null}</td>
            {isAdmin && <td>
                <input aria-label="Is Deleted" type="checkbox" checked={isDeleted} onChange={onChangeIsDeleted} />
                <span style={{color: error ? 'red' : 'black'}}>&nbsp;{isSaving ? 'Saving...' : error}</span>
            </td>}
        </tr>
        
    )
}

export default AnnotationManagementTableRow