import React, { Component } from 'react'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Spinner from 'react-bootstrap/Spinner'
import ListGroup from 'react-bootstrap/ListGroup'
import InputGroup from 'react-bootstrap/InputGroup'
import ColorPicker from '../PublicComponents/ColorPicker'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import UserSearchInput from '../PublicComponents/UserSearchInput'
import Popover from 'react-bootstrap/Popover'
import { ValidationWarning } from '../PublicComponents/HelperComponents';
import { ButtonGroup } from 'react-bootstrap'
import IcMDataService from '../../Dataservices/IcMDataService'
import AlertingService from '../../Dataservices/AlertingService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";
import Container from 'react-bootstrap/Container'
import IcMSelector from '../AlertingComponents/IcMSelector';

library.add(faGripLines);

function validateLegendForDashboard(tab, charts, list) {
    let legendList = Array(), validList = [...list]
    for (let chart of charts) {
        for (let series of chart.serverData) {
            if (series.ID !== "0") legendList.push(transformBackLegend(series.Name))
        }
    }
    // return a list with valid property.
    for (let alert of validList) {
        // There are two cases when the alert should change to invalid:
        // 1. Global alert in metrics tab.
        if (tab === "dailymetrics" && alert.Legend === null) alert.valid = false
        // 2. Legend alert with no existing legend.
        else if ((alert.Legend !== null) && !legendList.includes(alert.Legend)) alert.valid = false
        else alert.valid = true
    }
    return validList
}


function getDynamicListFromProps(props) {
    if (!props.chart || !props.chart.alertList || props.originator.OriginatorName !== "Dashboard") return []
    return validateLegendForDashboard(props.tab, props.charts, props.chart.alertList.DynamicAlerts.map(alert => ({
        Id: alert.Id,
        Legend: alert.DynamicThreshold.Legend,
        ZScore: alert.DynamicThreshold.ZScore,
        EmailNotification: alert.EmailNotification,
        IcmNotification: alert.IcmNotification,
        IcmSeverity: alert.IcmSeverity,
        AutoMitigate: alert.AutoMitigate,
        Email: alert.Email,
        IcmConfigId: alert.IcmConfigId,
        IsAlertEnabled: alert.DynamicThreshold.IsAlertEnabled,
        valid: true
    })))
}

function getStaticListFromProps(props) {
    if (!props.chart || !props.chart.alertList) return []
    if (props.originator.OriginatorName === "MSR") {
        return props.chart.alertList.StaticAlerts.map(alert => ({
            Id: alert.Id,
            Legend: alert.StaticThreshold.Legend,
            IsAboveThreshold: alert.StaticThreshold.IsAboveThreshold,
            SnoozeInterval: alert.StaticThreshold.SnoozeInterval || 0,
            Tolerence: alert.StaticThreshold.Tolerence || 30,
            EmailNotification: alert.EmailNotification,
            IcmNotification: alert.IcmNotification,
            IcmSeverity: alert.IcmSeverity,
            AutoMitigate: alert.AutoMitigate,
            Email: alert.Email,
            IcmConfigId: alert.IcmConfigId,
            Threshold: alert.StaticThreshold.Threshold || 0,
            IsAlertEnabled: alert.StaticThreshold.IsAlertEnabled,
            valid: true
        }))
    } else {
        return validateLegendForDashboard(props.tab, props.charts, props.chart.alertList.StaticAlerts.map(alert => ({
            Id: alert.Id,
            Legend: alert.StaticThreshold.Legend,
            IsAboveThreshold: alert.StaticThreshold.IsAboveThreshold,
            SnoozeInterval: alert.StaticThreshold.SnoozeInterval || 0,
            EmailNotification: alert.EmailNotification,
            IcmNotification: alert.IcmNotification,
            IcmSeverity: alert.IcmSeverity,
            AutoMitigate: alert.AutoMitigate,
            Email: alert.Email,
            IcmConfigId: alert.IcmConfigId,
            Threshold: alert.StaticThreshold.Threshold || 0,
            IsAlertEnabled: alert.StaticThreshold.IsAlertEnabled,
            valid: true
        })))
    }
}

function getGoallineListFromProps(props) {
    if (!props.chart || !props.chart.alertList || props.originator.OriginatorName !== "Dashboard") return []
    return props.chart.alertList.GoalLines.map(alert => ({
        Id: alert.Id,
        Name: alert.GoalLine.Name,
        Axis: alert.GoalLine.Axis,
        Color: alert.GoalLine.Color,
        Goal: alert.GoalLine.Goal || 0,
        IsAlertEnabled: alert.GoalLine.IsAlertEnabled,
        IsGoalLineEnabled: alert.GoalLine.IsGoalLineEnabled,
        LineType: alert.GoalLine.LineType
    }))
}

function transformLegend(legend, originator) {
    if (legend === null && originator === "Dashboard") return '(Global)'
    else if (!legend) return '(blank)'
    else return legend
}

function transformBackLegend(legend) {
    if (legend === '(Global)') return null
    else if (legend === '(blank)') return ''
    else return legend
}

class DataSuiteIndividualAlertModal extends Component {

    constructor(props) {
        super(props)

        const dynamicList = getDynamicListFromProps(props)
        const staticList = getStaticListFromProps(props)
        const goallineList = getGoallineListFromProps(props)

        this.typeToListMapping = { "DynamicAlert": "dynamicList", "StaticAlert": "staticList", "GoalLine": "goallineList" }

        this.state = {
            MSRScenario: props.MSRScenario,
            chart: props.chart,
            charts: props.charts,
            editPage: "",
            editStatus: "New",
            editIndex: -1,
            uploading: false,
            unSave: false,
            showDeleteModal: false,

            //Notification
            emailNotification: true,
            icmNotification: false,
            email: null,
            icmTeamInfo: null,
            icmServiceInfo: null,
            selectIcmTeam: false,
            icmSeverity: 3,
            icmConfigId: null,
            teamSelectMessage: 'Please select a service first',
            serviceList: [],
            teamList: [],
            icmSeverityOptions: [0, 1, 2, 3, 4],
            serviceTeamList: [],
            autoMitigate: false,

            // Dynamic Alert
            dynamicLegend: null,
            zscore: 3,
            dynamicList: dynamicList,

            // Static Alert
            staticLegend: null,
            staticTriggerValue: 0,
            staticTriggerValueDisplay: 0,
            tolerence: 30,
            snoozeInterval: 0,
            staticList: staticList,

            // Goal Line
            goallineName: 'Goal line',
            color: '#000000',
            axis: 'Latency',
            lineType: 'Dash',
            goal: 0,
            goalLineEnabled: true,
            goallineList: goallineList,

            // Validation
            isZscoreValid: true,
            isTriggerValueValid: true,
            isSnoozeIntervalValid: true,
            isGoalLineValid: true,
            isGoalLineNameValid: true,
            error: ``,

            // Update
            updatedTime: props.updatedTime,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.charts !== prevState.charts || nextProps.chart !== prevState.chart || nextProps.updatedTime != prevState.updatedTime) {
            const dynamicList = getDynamicListFromProps(nextProps)
            const staticList = getStaticListFromProps(nextProps)
            return {
                url: nextProps.url,
                dynamicList: dynamicList,
                staticList: staticList,
                charts: nextProps.charts,
                chart: nextProps.chart,
                updatedTime: nextProps.updatedTime,
            }
        }
        return null
    }

    componentDidMount() {
        let { serviceList } = this.state
        if (serviceList.length === 0) {
            this.populateServiveList()
        }
    }

    initIcMInfoByOriginator = () => {
        let { originator } = this.props
        if (originator.OriginatorName === "Dashboard") {
            this.setState({
                hasDefaultIcmTeam: this.props.dashboardInfo.icmConfigId ? true : false,
                isIcmTeamValid: this.props.dashboardInfo.icmConfigId ? true : false,
                selectIcmTeam: this.props.dashboardInfo.icmConfigId ? false : true,
            })
        }
        else if (originator.OriginatorName === "MSR") {
            this.setState({
                hasDefaultIcmTeam: this.props.icmConfigId ? true : false,
                isIcmTeamValid: this.props.icmConfigId ? true : false,
                selectIcmTeam: this.props.icmConfigId ? false : true,
            })
        }
    }

    getIcmConfigInfo = (id, index, type) => {
        if (id == null) return
        IcMDataService.getIcmTeam(id).then(icmResult => {
            const result = icmResult.data
            let serviceInfo = []
            serviceInfo.value = result.ServiceConnectorId
            serviceInfo.label = result.ServiceName
            serviceInfo.serviceId = result.ServiceId

            let teamInfo = []
            teamInfo.value = result.RoutingId
            teamInfo.label = result.TeamName

            let { staticList, dynamicList } = this.state
            if (type === "static") {
                staticList[index].IcmTeamInfo = teamInfo
                staticList[index].IcmServiceInfo = serviceInfo
                this.setState({ staticList: staticList })
            } else if (type === "dynamic") {
                dynamicList[index].IcmTeamInfo = teamInfo
                dynamicList[index].IcmServiceInfo = serviceInfo
                this.setState({ dynamicList: dynamicList })
            }
        })
    }


    addAlert = () => {
        const { editPage, emailNotification, icmNotification, icmSeverity, email, icmServiceInfo, icmTeamInfo, autoMitigate} = this.state
        // Validate alert input 
        if (!this.validateParameters(editPage) || !this.validateAdd(editPage, -1)) return
        const listName = this.typeToListMapping[editPage]
        const list = this.state[listName]
        let addIcmConfig = this.getIcmConfigId()
        addIcmConfig.then(result => {
            this.setState({
                icmConfigId: result.data === 0 ? null : result.data
            })
            if (list) {
                let alert = {}
                if (editPage === "DynamicAlert") {
                    const { dynamicLegend, zscore } = this.state
                    alert = {
                        Legend: dynamicLegend,
                        EvtLog: null,
                        ZScore: zscore || 3,
                        IsAlertEnabled: true,
                        EmailNotification: emailNotification,
                        IcmNotification: icmNotification,
                        IcmSeverity: icmSeverity,
                        AutoMitigate: autoMitigate,
                        IcmConfigId: result.data === 0 ? null : result.data,
                        IcmServiceInfo: icmServiceInfo,
                        IcmTeamInfo: icmTeamInfo,
                        Email: email,
                        valid: true,
                    }
                } else if (editPage === "StaticAlert") {
                    const { staticLegend, staticTriggerAbove, snoozeInterval, staticTriggerValue, tolerence, autoMitigate } = this.state
                    alert = {
                        Legend: staticLegend,
                        EvtLog: null,
                        IsAboveThreshold: staticTriggerAbove,
                        SnoozeInterval: snoozeInterval || 0,
                        Threshold: staticTriggerValue || 0,
                        Tolerence: tolerence || 30,
                        IsAlertEnabled: true,
                        EmailNotification: emailNotification,
                        IcmNotification: icmNotification,
                        IcmSeverity: icmSeverity,
                        AutoMitigate: autoMitigate,
                        IcmConfigId: result.data === 0 ? null : result.data,
                        IcmServiceInfo: icmServiceInfo,
                        IcmTeamInfo: icmTeamInfo,
                        Email: email,
                        valid: true,
                    }
                } else if (editPage === "GoalLine") {
                    const { goallineName, axis, color, goal, goalLineEnabled, lineType } = this.state
                    alert = {
                        Name: goallineName,
                        Axis: axis,
                        Color: color,
                        EvtLog: null,
                        Goal: goal || 0,
                        IsGoalLineEnabled: goalLineEnabled,
                        IsAlertEnabled: true,
                        LineType: lineType
                    }
                }
                list.push(alert)
                this.setState({ [listName]: list, editPage: '', unSave: false, error: "" })
                this.onSave()
            }
            this.resetAlertPage()
        })      
    }

    onDeleteItem = () => {
        const { editPage, editIndex } = this.state
        const listName = this.typeToListMapping[editPage]
        const list = this.state[listName]
        if (list && editIndex < list.length) {
            list.splice(editIndex, 1)
            this.setState({
                [listName]: list,
                editPage: '',
                showDeleteModal: false
            })
            this.onSave()
        } else {
            this.setState({ error: "Alert doesn't exist." })
        }
    }

    changeItem = (type, index) => {
        const { hasDefaultIcmTeam } = this.state
        if (type === "DynamicAlert") {
            const { dynamicList } = this.state
            if (dynamicList && index < dynamicList.length) {
                const alert = dynamicList[index]
                this.setState({
                    editPage: "DynamicAlert",
                    editStatus: "Edit",
                    editIndex: index,
                    dynamicLegend: alert.Legend,
                    zscore: alert.ZScore,
                    emailNotification: alert.EmailNotification,
                    icmNotification: alert.IcmNotification,
                    icmSeverity: alert.IcmSeverity,
                    autoMitigate: alert.AutoMitigate,
                    icmConfigId: alert.IcmConfigId,
                    email: alert.Email,
                    unSave: false,
                    hasDefaultIcmTeam: hasDefaultIcmTeam,
                    isIcmTeamValid: alert.IcmConfigId ? true : hasDefaultIcmTeam,
                    selectIcmTeam: alert.IcmConfigId ? true : !hasDefaultIcmTeam,
                })
                if (alert.IcmConfigId != null) {
                    IcMDataService.getIcmTeam(alert.IcmConfigId).then(icmResult => {
                        const result = icmResult.data
                        let serviceInfo = []
                        serviceInfo.value = result.ServiceConnectorId
                        serviceInfo.label = result.ServiceName
                        serviceInfo.serviceId = result.ServiceId
                        let teamInfo = []
                        teamInfo.value = result.RoutingId
                        teamInfo.label = result.TeamName
                        this.setState({
                            icmServiceInfo: serviceInfo,
                            icmTeamInfo: teamInfo,
                        })
                    })
                }
            }
        } else if (type === "StaticAlert") {
            const { originator, thresholdList } = this.props
            const { staticList } = this.state
            if (staticList && index < staticList.length) {
                const alert = staticList[index]
                this.setState({
                    editPage: "StaticAlert",
                    editStatus: "Edit",
                    editIndex: index,
                    staticLegend: alert.Legend,
                    staticTriggerAbove: alert.IsAboveThreshold,
                    snoozeInterval: alert.SnoozeInterval,
                    tolerence: alert.Tolerence,
                    staticTriggerValue: alert.Threshold,
                    emailNotification: alert.EmailNotification,
                    icmNotification: alert.IcmNotification,
                    icmSeverity: alert.IcmSeverity,
                    autoMitigate: alert.AutoMitigate,
                    icmConfigId: alert.IcmConfigId,
                    email: alert.Email,
                    hasDefaultIcmTeam: hasDefaultIcmTeam,
                    isIcmTeamValid: alert.IcmConfigId ? true : hasDefaultIcmTeam,
                    selectIcmTeam: alert.IcmConfigId ? true : !hasDefaultIcmTeam,
                })
                if (originator.OriginatorName === "MSR") {
                    this.setState({
                        staticTriggerValueDisplay: thresholdList[alert.Legend]
                    })
                }
                if (alert.IcmConfigId != null) {
                    IcMDataService.getIcmTeam(alert.IcmConfigId).then(icmResult => {
                        const result = icmResult.data
                        let serviceInfo = []
                        serviceInfo.value = result.ServiceConnectorId
                        serviceInfo.label = result.ServiceName
                        serviceInfo.serviceId = result.ServiceId
                        let teamInfo = []
                        teamInfo.value = result.RoutingId
                        teamInfo.label = result.TeamName
                        this.setState({
                            icmServiceInfo: serviceInfo,
                            icmTeamInfo: teamInfo,
                        })
                    })
                }
            }
        } else if (type === "GoalLine") {
            const { goallineList } = this.state
            if (goallineList && index < goallineList.length) {
                const alert = goallineList[index]
                this.setState({
                    editPage: "GoalLine",
                    editStatus: "Edit",
                    editIndex: index,
                    goallineName: alert.Name,
                    axis: alert.Axis,
                    color: alert.Color,
                    goal: alert.Goal,
                    goalLineEnabled: alert.IsGoalLineEnabled,
                    lineType: alert.LineType,
                    unSave: false,
                })
            }
        }
    }

    editAlert = () => {
        const { editPage, editIndex, icmServiceInfo, icmTeamInfo, icmSeverity, email, emailNotification, icmNotification, autoMitigate} = this.state
        // Validate alert input 
        if (!this.validateParameters(editPage)) return
        const listName = this.typeToListMapping[editPage]
        const list = this.state[listName]
        let addIcmConfig = this.getIcmConfigId()
        addIcmConfig.then(result => {
            this.setState({
                icmConfigId: result.data === 0 ? null : result.data
            })
            if (list && editIndex < list.length) {
                if (list[editIndex].IsAlertEnabled && !this.validateAdd(editPage, editIndex)) return
                if (editPage === "DynamicAlert") {
                    const { dynamicLegend, zscore } = this.state
                    list[editIndex].Legend = dynamicLegend
                    list[editIndex].ZScore = zscore
                    list[editIndex].EmailNotification = emailNotification
                    list[editIndex].IcmNotification = icmNotification
                    list[editIndex].IcmServiceInfo = icmServiceInfo
                    list[editIndex].IcmTeamInfo = icmTeamInfo
                    list[editIndex].IcmSeverity = icmSeverity
                    list[editIndex].AutoMitigate = autoMitigate
                    list[editIndex].IcmConfigId = result.data === 0 ? null : result.data
                    list[editIndex].Email = email
                    list[editIndex].valid = true
                } else if (editPage === "StaticAlert") {
                    const { staticLegend, staticTriggerAbove, snoozeInterval, staticTriggerValue, tolerence } = this.state
                    list[editIndex].Legend = staticLegend
                    list[editIndex].IsAboveThreshold = staticTriggerAbove
                    list[editIndex].SnoozeInterval = snoozeInterval || 0
                    list[editIndex].Tolerence = tolerence || 30
                    list[editIndex].EmailNotification = emailNotification
                    list[editIndex].IcmNotification = icmNotification
                    list[editIndex].IcmServiceInfo = icmServiceInfo
                    list[editIndex].IcmTeamInfo = icmTeamInfo
                    list[editIndex].IcmSeverity = icmSeverity
                    list[editIndex].AutoMitigate = autoMitigate
                    list[editIndex].IcmConfigId = result.data === 0 ? null : result.data
                    list[editIndex].Email = email
                    list[editIndex].Threshold = staticTriggerValue || 0
                    list[editIndex].valid = true
                } else if (editPage === "GoalLine") {
                    const { goallineName, axis, color, goal, goalLineEnabled, lineType } = this.state
                    list[editIndex].Name = goallineName
                    list[editIndex].Axis = axis
                    list[editIndex].Color = color
                    list[editIndex].Goal = goal || 0
                    list[editIndex].IsGoalLineEnabled = goalLineEnabled
                    list[editIndex].LineType = lineType
                }
                this.setState({ [listName]: list, unSave: false, error: "" })
                this.onSave()
            } else {
                this.setState({ error: "Alert doesn't exist." })
            }
        })
        
    }

    toggleDeleteModal = () => {
        this.setState(prevState => {
            return {
                showDeleteModal: !prevState.showDeleteModal,
            }
        })
    }

    toggleAlert = (type, index) => {
        const listName = this.typeToListMapping[type]
        const list = this.state[listName]
        if (list && index < list.length) {
            if (!list[index].IsAlertEnabled && !this.validateAdd(type, index)) return
            list[index].IsAlertEnabled = !list[index].IsAlertEnabled
            if (type === "GoalLine") list[index].IsGoalLineEnabled = list[index].IsAlertEnabled;
            this.setState({ [listName]: list })
            this.onSave()
        }
    }

    togglePage = () => {
        this.setState({
            editPage: "",
            unSave: false,
        })
    }

    onZscoreInput = e => {
        const number = parseFloat(e.target.value)
        this.setState({
            zscore: number,
            isZscoreValid: !isNaN(number),
            unSave: true,
        })
    }

    onTriggerValueChange = e => {
        const number = parseFloat(e.target.value)
        this.setState({
            staticTriggerValue: number,
            isTriggerValueValid: !isNaN(number) && number > 0,
            unSave: true,
        })
    }

    onTolerenceChange = e => {
        let { staticTriggerValueDisplay, staticTriggerAbove } = this.state
        const number = parseFloat(e.target.value)
        this.setState({
            tolerence: number,
            unSave: true,
        })
        if (staticTriggerAbove) {
            this.setState({
                staticTriggerValue: staticTriggerValueDisplay * (1 + number * 0.01),
            })
        } else {
            this.setState({
                staticTriggerValue: staticTriggerValueDisplay * (1 - number * 0.01),
            })
        }
    }

    toggleStaticTriggerAbove = () => {
        this.setState(prevState => {
            return {
                staticTriggerAbove: !prevState.staticTriggerAbove,
                unSave: true,
            }
        })
    }

    onSnoozeIntervalChange = e => {
        const number = parseInt(e.target.value)
        this.setState({
            snoozeInterval: number,
            isSnoozeIntervalValid: !isNaN(number) && number > 0,
            unSave: true,
        })
    }

    onUsersChangeHandler = newVal => {
        this.setState({
            email: newVal.slice(),
            unSave: true
        })
    }

    populateServiveList = () => {
        if(this.props.icmServices) {
            this.props.icmServices.then(serviceSelectList => {
                this.setState({
                    serviceList: serviceSelectList,
                    isLoadingServices: false
                })
            });
        }else {
            let index = 0;
            let serviceSelectList = [];
            const serviceQuery = IcMDataService.getServices().then(res => {
                for (; index < res.data.length; index++) {
                    const service = res.data[index];
                    serviceSelectList.push({
                        value: service.Id,
                        label: service.ServiceName,
                        serviceId: service.ServiceId,
                    });
                }
                this.setState({
                    serviceList: serviceSelectList,
                    isLoadingServices: false
                })
                return serviceSelectList
            });
            this.props.setIcmServices(serviceQuery)
        }
    }

    onToggleIcm = () => {
        if (this.state.hasDefaultIcmTeam) {
            this.setState(prevState => {
                return {
                    selectIcmTeam: !prevState.selectIcmTeam,
                    unSave: true,
                    icmConfigId: null,
                    icmTeamInfo: null,
                    icmServiceInfo: null
                }
            })
        }
    }

    onToggleAutoMitigate = () => {
        this.setState(prevState => {
            return {
                autoMitigate: !prevState.autoMitigate,
                unSave: true,
            }
        })
    }

    setServiceInfo = (icmServiceInfo) => {
        const { hasDefaultIcmTeam } = this.state
        this.setState({
            icmServiceInfo: icmServiceInfo,
            teamSelectMessage: 'Searching...',
            icmTeamInfo: null,
            teamList: [],
            isIcmTeamValid: hasDefaultIcmTeam,
            unSave: true
        });

        if (icmServiceInfo != null) {
            this.getTeamList(icmServiceInfo.serviceId)
        }
    }

    setTeamInfo = (icmTeamInfo) => {
        const { icmServiceInfo, hasDefaultIcmTeam } = this.state
        this.setState({
            icmTeamInfo: icmTeamInfo,
            isIcmTeamValid: icmTeamInfo == null ? hasDefaultIcmTeam : true,
            unSave: true,
            icmServiceInfo: icmTeamInfo == null ? null : icmServiceInfo,
        });
    }

    getIcmConfigId = () => {
        const { icmServiceInfo, icmTeamInfo } = this.state
        if (icmTeamInfo != null) {
            let request = {};
            request.ServiceConnectorId = icmServiceInfo ? icmServiceInfo.value : null
            request.TeamName = icmTeamInfo ? icmTeamInfo.label : null
            request.RoutingId = icmTeamInfo ? icmTeamInfo.value : null
            return IcMDataService.addIcmConfig(request)
        }
        return IcMDataService.addIcmConfig(null)
    }

    getTeamList = (id) => {
        let index = 0;
        let teamSelectListConstructor = [];
        IcMDataService.getTeams(id).then(res => {
            let uniqueArray = res.data.filter((object, index) => index === res.data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object)))
            let allTeams = uniqueArray.map(a => a.Item2)
            const duplicates = new Set(allTeams.filter((item, index) => index !== allTeams.indexOf(item)));
            for (; index < uniqueArray.length; index++) {
                const series = uniqueArray[index];
                let routingId = series.Item1
                let teamName = series.Item2
                let nameWithId = teamName + ' (' + routingId + ')'
                teamSelectListConstructor.push({
                    value: routingId,
                    label: duplicates.has(teamName) ? nameWithId : teamName,
                });
            }
            this.setState({ teamList: teamSelectListConstructor })
        });
    }

    onGoalChange = e => {
        const { axis } = this.state
        const number = parseFloat(e.target.value)
        let isValid = false
        if (!isNaN(number) && axis === 'Ratio' && number >= 0 && number <= 1) {
            isValid = true
        } else if (!isNaN(number) && axis !== 'Ratio') {
            isValid = true
        }
        this.setState({
            goal: number,
            isGoalLineValid: isValid,
            unSave: true,
        })
    }

    onAxisChange = (a) => { this.setState({ axis: a, unSave: true, }) }

    onLineTypeChange = (type) => { this.setState({ lineType: type, unSave: true, }) }

    onColorChange = (color) => { this.setState({ color: color.hex, unSave: true, }) }

    onEmailNotificationChange = (e) => {
        this.setState({
            emailNotification: e.target.checked,
            unSave: true,
        })
    }

    onIcmNotificationChange = (e) => {
        this.initIcMInfoByOriginator()
        this.setState({
            icmNotification: e.target.checked,
            unSave: true
        })
    }

    onIcmSeverityChange = (index) => {
        const { icmSeverityOptions } = this.state
        this.setState({ icmSeverity: icmSeverityOptions[index], unSave: true, })
    }

    selectDynamicLegend = (item) => { this.setState({ dynamicLegend: item.value, unSave: true, }); }

    selectStaticLegend = (item) => {
        const { originator, thresholdList } = this.props
        this.setState({ staticLegend: item.value, unSave: true, })
        if (originator.OriginatorName === "MSR") {
            this.setState({
                staticTriggerValueDisplay: thresholdList[item.value],
                staticTriggerValue: thresholdList[item.value] * 1.3
            })
        }
    }

    onGoalLineChange = (e) => {
        const re = new RegExp('^[ a-zA-Z0-9]+$')
        if (re.test(e.target.value)) {
            this.setState({ goallineName: e.target.value, unSave: true, isGoalLineNameValid: true, })
        } else {
            this.setState({ goallineName: e.target.value, unSave: true, isGoalLineNameValid: false, })
        }
    }

    changeAlertPage = (page) => {
        if (!this.validateCount(page)) return
        this.resetAlertPage()
        this.setState({ editPage: page, editStatus: "New" })
    }

    resetAlertPage = () => {
        const lineNum = this.state.goallineList.length > 0 ? this.state.goallineList.length.toString() : ''
        this.setState({
            // Dynamic Alert
            dynamicLegend: null, zscore: 3,
            // Static Alert
            staticLegend: null, staticTriggerValue: 0, staticTriggerValueDisplay: 0, snoozeInterval: 0, tolerence: 30,
            // Goal Line
            goallineName: 'Goal line ' + lineNum, color: '#000000', axis: 'Latency', lineType: 'Dash', goal: 0, goalLineEnabled: true,
            // Validation
            isZscoreValid: true, isTriggerValueValid: true, isSnoozeIntervalValid: true, isGoalLineValid: true,
            //Alert Notification
            emailNotification: true,
            icmNotification: false,
            email: [],
            icmTeamInfo: null,
            icmServiceInfo: null,
            icmSeverity: 3,
            autoMitigate: false,
            icmConfigId: null,
        })
        this.initIcMInfoByOriginator()
    }

    closeAlert = () => { this.setState({ error: "" }) }

    validateParameters = (editPage) => {
        const { isZscoreValid, isTriggerValueValid, isSnoozeIntervalValid, isGoalLineValid, isGoalLineNameValid, isIcmTeamValid, icmNotification, icmConfigId, selectIcmTeam, icmTeamInfo } = this.state
        if ((editPage === "DynamicAlert" && (!isZscoreValid || (!isIcmTeamValid && icmNotification) || (selectIcmTeam && !icmTeamInfo && icmNotification)))
            || (editPage === "StaticAlert" && (!isTriggerValueValid || !isSnoozeIntervalValid || (!isIcmTeamValid && icmNotification) || (selectIcmTeam && !icmTeamInfo && icmNotification)))
            || (editPage === "GoalLine" && (!isGoalLineValid || !isGoalLineNameValid))) {
            this.setState({ error: "Please input valid parameters." })
            return false
        }
        return true
    }

    validateAdd = (type, index) => {
        if (type === "DynamicAlert" || type === "StaticAlert") {
            const { dynamicList, staticList, dynamicLegend, staticLegend, emailNotification, icmNotification } = this.state
            const { tab, originator } = this.props
            const list = (type === "DynamicAlert") ? dynamicList : staticList
            const legend = (type === "DynamicAlert") ? dynamicLegend : staticLegend
            if (legend === null) {
                //check for MSR first
                if (originator.OriginatorName === "MSR") {
                    this.setState({ error: "Must choose a legend for MSR." })
                    return false
                }
                // Check alert list when adding a global alert.
                // 1. Global alert can not be enabled twice.
                if (list.filter((x, ind) => { return (ind !== index && x.IsAlertEnabled && (x.Legend === null)) }).length > 0) {
                    this.setState({ error: "The global alert can not be trigger twice." })
                    return false
                }
                // 2. Global alert can not be enabled when there existing enabled legend alerts, except checkpoint chart.
                if (tab !== "checkpoint" && list.filter((x, ind) => { return (ind !== index && x.IsAlertEnabled) }).length > 0) {
                    this.setState({ error: "The global alert will trigger duplicate alerts for exiting legend alert(s)." })
                    return false
                }
                // 3. Global alert can not be added to metrics chart.
                if (tab === "dailymetrics") {
                    this.setState({ error: "Metric can not support global alert." })
                    return false
                }
            } else {
                // Check alert list when adding a legend alert. 
                // 1. Same legend can not be added twice.
                if (list.filter((x, ind) => { return (ind !== index && x.IsAlertEnabled && (x.Legend === legend)) }).length > 0) {
                    this.setState({ error: "There is an existing alert for this legend : " + legend })
                    return false
                }
                // 2. Legend alert can not be enabled when there is a global alert, except checkpoint chart.
                if (tab !== "checkpoint" && list.filter((x, ind) => { return (ind !== index && x.IsAlertEnabled && (x.Legend === null)) }).length > 0) {
                    this.setState({ error: "There is a global alert which will enable an alert for each legend." })
                    return false
                }
            }
            // Common Validation: 
            // 1. Invalid item can not be enabled.
            if ((index >= 0) && (!list[index].valid)) {
                this.setState({ error: "Invalid item can not be enabled." })
                return false
            }
            if (!emailNotification && !icmNotification) {
                this.setState({ error: "Please select at least one notification method." })
                return false
            }
        } else if (type === "GoalLine") {
            const { goallineList, goallineName } = this.state
            // Check duplicate name
            if (goallineList.filter((x, ind) => { return (ind !== index && x.Name === goallineName) }).length > 0) {
                this.setState({ error: "The goal line name has been used." })
                return false
            }
        }
        return true
    }

    validateCount = (type) => {
        if (type === "DynamicAlert" || type === "StaticAlert") {
            const { dynamicList, staticList } = this.state
            const { tab } = this.props
            const totalLen = dynamicList.length + staticList.length
            // The number of alerts can not be larger than 10.
            if (totalLen >= 10) {
                this.setState({ error: "Too many alerts are set. The total number of alerts can not be larger than 10." })
                return false
            }
            // Check global alert except checkpoint chart. If there is a enabled global alert, new alert can not be added.
            if (tab === "checkpoint") { return true }
            const list = (type === "DynamicAlert") ? dynamicList : staticList
            if (list.filter(x => { return (x.IsAlertEnabled && (x.Legend === null)) }).length > 0) {
                this.setState({ error: "There is a global alert which will enable an alert for each legend." })
                return false
            }
        } else if (type === "GoalLine") {
            const { goallineList } = this.state
            if (goallineList.length >= 10) {
                this.setState({ error: "Too many goallines. The total number of goallines can not be larger than 10." })
                return false
            }
        }
        return true
    }

    onSave = () => {
        const { dynamicList, staticList, goallineList } = this.state
        const { chart, toggleModal, onSave, onChangeValidation, originator } = this.props
        let invalid = false
        dynamicList.map((alert) => { if (!alert.valid) invalid = true })
        staticList.map((alert) => { if (!alert.valid) invalid = true })
        if (invalid) {
            this.setState({ error: "Please fix invalid alert(s)." })
            return
        }
        const dynamicAlerts = dynamicList.map(alert => ({
            Id: alert.Id,
            DynamicThreshold: {
                Legend: alert.Legend,
                EvtLog: null,
                IsAlertEnabled: alert.IsAlertEnabled,
                ZScore: alert.ZScore || 3
            },
            IcmSeverity: alert.IcmSeverity,
            IcmConfigId: alert.IcmConfigId,
            EmailNotification: alert.EmailNotification,
            IcmNotification: alert.IcmNotification,
            AutoMitigate: alert.AutoMitigate,
            Email: (alert.Email == null || alert.Email.length === 0) ? null : alert.Email,
        }))
        const staticAlerts = staticList.map(alert => ({
            Id: alert.Id,
            StaticThreshold: {
                Legend: alert.Legend,
                EvtLog: null,
                IsAlertEnabled: alert.IsAlertEnabled,
                IsAboveThreshold: alert.IsAboveThreshold,
                SnoozeInterval: alert.SnoozeInterval || 0,
                Tolerence: alert.Tolerence || 30,
                Threshold: alert.Threshold || 0
            },
            IcmSeverity: alert.IcmSeverity,
            IcmConfigId: alert.IcmConfigId,
            EmailNotification: alert.EmailNotification,
            IcmNotification: alert.IcmNotification,
            AutoMitigate: alert.AutoMitigate,
            Email: (alert.Email == null || alert.Email.length === 0) ? null : alert.Email,
        }))
        const goalLines = goallineList.map(alert => ({
            Id: alert.Id,
            GoalLine: {
                Name: alert.Name,
                Axis: alert.Axis,
                Color: alert.Color,
                EvtLog: null,
                Goal: alert.Goal || 0,
                IsAlertEnabled: alert.IsAlertEnabled,
                IsGoalLineEnabled: alert.IsGoalLineEnabled,
                LineType: alert.LineType
            }
        }))
        const payload = {
            ChartId: chart.id,
            DynamicAlerts: dynamicAlerts,
            StaticAlerts: staticAlerts,
            GoalLines: goalLines,
            OriginatorId: originator.OriginatorID,
        }

        this.setState({ uploading: true })
        onSave({ ...chart, alertList: payload }, false)

        AlertingService.changeAlert(payload).then(res => {
            const alertList = res.data
            alertList.DynamicAlerts.map((alert, index) => { dynamicList[index].Id = alert.Id })
            alertList.StaticAlerts.map((alert, index) => { staticList[index].Id = alert.Id })
            alertList.GoalLines.map((alert, index) => { goallineList[index].Id = alert.Id })
            this.setState({ uploading: false, dynamicList: dynamicList, staticList: staticList, goallineList: goallineList, error: "" })
            onChangeValidation(true)
        }).catch(err => {
            this.setState({ uploading: false })
            this.setState({ error: err.message })
            onChangeValidation(false)
        })
    }

    SelectIcmModal() {
        const { originator } = this.props
        const { serviceList, teamSelectMessage, teamList, icmServiceInfo, icmTeamInfo, isIcmTeamValid, icmNotification } = this.state
        return (
            <React.Fragment>
                <IcMSelector serviceList={serviceList} icmServiceInfo={icmServiceInfo} setServiceInfo={this.setServiceInfo} teamList={teamList} icmTeamInfo={icmTeamInfo} teamSelectMessage={teamSelectMessage} setTeamInfo={this.setTeamInfo}></IcMSelector>
                {!isIcmTeamValid && icmNotification && <ValidationWarning text={"Please select an Icm team. The " + (originator.OriginatorName === "Dashboard" ? "dashboard" : "scenario") + " entries are blank."} />}
            </React.Fragment>
        )
    }

    AlertNotificationModal() {
        const { originator } = this.props
        const { emailNotification, icmNotification, icmSeverity, icmSeverityOptions, email, selectIcmTeam, autoMitigate } = this.state
        return (
            <React.Fragment>
                <Form.Group as={Row} controlId="notificationmethod">
                    <Form.Label column sm="4" className="noWrap">Alert Notification Method</Form.Label>
                </Form.Group>

                <Form.Group controlId="emailnotification">
                    <Row className='pl-3'>
                        <Col><Form.Check type="checkbox" label="Send Email" checked={emailNotification} onChange={this.onEmailNotificationChange} /></Col>
                    </Row>
                </Form.Group>

                {emailNotification && <Form.Group as={Row} controlId="email" className='pl-3'>
                    <Form.Label column sm="4" className="noWrap">Send Emails To</Form.Label>
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Popover id="popover-basic">
                                <Popover.Content>
                                    Emails will always be sent to the dashboard owners and members. You can add additional email recipients in this field. <strong>The dashboard entries will apply by default if you leave this field blank.</strong>
                                </Popover.Content>
                            </Popover>
                        }>
                        <Col>
                            <UserSearchInput name="user" persons={email ? email : []} onChange={this.onUsersChangeHandler} />
                        </Col>
                    </OverlayTrigger>
                </Form.Group>}

                <Form.Group controlId="icmnotification">
                    <Row className='pl-3'>
                        <Col sm="9"><Form.Check type="checkbox" label="Create IcM Incident with Severity" checked={icmNotification} onChange={this.onIcmNotificationChange} /></Col>
                        <Col className='p-0'>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    {icmSeverityOptions[icmSeverity]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {icmSeverityOptions.map((_option, index) => {
                                        return (
                                            <Dropdown.Item onClick={() => this.onIcmSeverityChange(index)}>{icmSeverityOptions[index]}</Dropdown.Item>
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Form.Group>
                {icmNotification && <Form.Group as={Row} controlId="autoMitigation" className='pl-3'>
                    <Col>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        {selectIcmTeam ? "You can switch on the toggle button to enable incident auto mitigation on this alert." : "You can switch off the toggle button to disable incident auto mitigation for this alert."}
                                    </Popover.Content>
                                </Popover>
                            }>
                        <div><Form.Switch style={{ float: "left" }} label="Auto Mitigation" onChange={this.onToggleAutoMitigate} checked={autoMitigate} /></div>
                        </OverlayTrigger>
                    </Col>
                </Form.Group>}
                {icmNotification && <Form.Group as={Row} controlId="icmTeamId" className='pl-3'>
                    <Col>
                        <OverlayTrigger
                            placement="left"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        {selectIcmTeam ? "You can switch on the toggle button to use the default values entered on the " + (originator.OriginatorName === "Dashboard" ? "dashboard" : "scenario") + " for this alert." : "You can switch off the toggle button to choose a different IcM owning team for this alert."}
                                    </Popover.Content>
                                </Popover>
                            }>
                            <div>
                                <Form.Switch style={{ float: "left" }} label={originator.OriginatorName === "Dashboard" ? "Use Default from Dashboard" : "Use Default from Scenario"} onChange={this.onToggleIcm} checked={!selectIcmTeam} />
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Form.Group>}
                {icmNotification && selectIcmTeam && this.SelectIcmModal()}
            </React.Fragment>
        )
    }

    DynamicAlertEdit(legendoption) {
        const { tab, originator } = this.props
        const { dynamicLegend, zscore, isZscoreValid } = this.state
        return (
            <React.Fragment>
                <Form.Group as={Row} controlId="dynamicLegend">
                    <Form.Label column sm="4">Legend</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-dynamiclegend">
                                    <Popover.Content>
                                        Please select the legend which you want for your alert.
                                        {(tab === "checkpoint") ? <strong> (Global) means Detect Changes in Overall Latency of all Checkpoints Together in Current Chart.</strong> : (
                                            (tab === "dailymetrics") ? <strong> Metric tab doesn't support global alert.</strong> :
                                                <strong> (Global) means enabling alerts for all legends.</strong>
                                        )}
                                    </Popover.Content>
                                </Popover>
                            }>
                            <div>
                                <Select placeholder="Select legend"
                                    options={legendoption}
                                    onChange={this.selectDynamicLegend}
                                    value={(tab === "dailymetrics" && dynamicLegend === null) ? null : { value: dynamicLegend, label: transformLegend(dynamicLegend, originator.OriginatorName) }}
                                />
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dynamicalertPane">
                    <Form.Label column sm="4">Z-Score(+/-)</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-zscore">
                                    <Popover.Content>
                                        Please enter the z-value which you want as guard rails for your alerts in this chart. &#013;
                                        The Z-value is a test statistic for Z-tests that measures the difference between an observed statistic and its hypothesized population
                                        parameter in units of the standard deviation. For example, a series of latency data has a mean value of 200 ms and a standard deviation of 10 ms.
                                        A latency value of 230 ms has a Z-value of 3, because it is three standard deviations greater than the mean.&#013;
                                        RECOMMENDED VALUE IS 3.
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Form.Control type="number" min={0} step={0.001} value={zscore} onChange={this.onZscoreInput} />
                        </OverlayTrigger>
                        {!isZscoreValid && <ValidationWarning text={"Please input a valid decimal"} />}
                    </Col>
                </Form.Group>
                {this.AlertNotificationModal()}
            </React.Fragment>
        )
    }

    StaticAlertEdit(legendoption) {
        const { tab, originator } = this.props
        const { staticLegend, staticTriggerAbove, staticTriggerValue, staticTriggerValueDisplay, snoozeInterval, isTriggerValueValid, isSnoozeIntervalValid, tolerence } = this.state
        return (
            <React.Fragment>
                <Form.Group as={Row} controlId="staticLegend">
                    <Form.Label column sm="4">Legend</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-dynamiclegend">
                                    <Popover.Content>
                                        Please select the legend which you want for your alert.
                                        {(originator.OriginatorName === "Dashboard") ? ((tab === "checkpoint") ? <strong> (Global) means Detect Changes in Overall Latency of all Checkpoints Together in Current Chart.</strong> : (
                                            (tab === "dailymetrics") ? <strong> Metric tab doesn't support global alert.</strong> :
                                                <strong> (Global) means enabling alerts for all legends.</strong>
                                        )) : <strong> Global alerts are not available for MSR.</strong>}
                                    </Popover.Content>
                                </Popover>
                            }>
                            <div>
                                <Select placeholder="Select legend"
                                    options={legendoption}
                                    onChange={this.selectStaticLegend}
                                    value={(tab === "dailymetrics" && staticLegend === null) ? null : { value: staticLegend, label: transformLegend(staticLegend, originator.OriginatorName) }}
                                />
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="trigger">
                    <Form.Label column sm="4">Threshold</Form.Label>
                    <Col sm="8">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <Button variant={staticTriggerAbove ? "outline-success" : "outline-warning"} onClick={this.toggleStaticTriggerAbove}>{staticTriggerAbove ? "Above" : "Below"}</Button>
                            </InputGroup.Prepend>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Content>
                                            Please enter the threshold number which you want as the max/min for your scenario.
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <Form.Control type="number" value={originator.OriginatorName === "MSR" ? staticTriggerValueDisplay : staticTriggerValue} onChange={this.onTriggerValueChange} disabled={originator.OriginatorName === "MSR" ? true : false} />
                            </OverlayTrigger>
                        </InputGroup>
                        {!isTriggerValueValid && <ValidationWarning text={"Positive numbers only"} />}
                    </Col>
                </Form.Group>
                {originator.OriginatorName === "MSR" && <Form.Group as={Row} controlId="tolerence">
                    <Form.Label column sm="4">Tolerence %</Form.Label>
                    <Col sm="8">
                        <InputGroup>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Popover id="popover-basic">
                                        <Popover.Content>
                                            Please enter the threshold number which you want as the max/min for your scenario.
                                        </Popover.Content>
                                    </Popover>
                                }>
                                <Form.Control type="number" min={0} value={tolerence} onChange={this.onTolerenceChange} />
                            </OverlayTrigger>
                        </InputGroup>
                        </Col>
                </Form.Group>}

                {originator.OriginatorName === "Dashboard" && <Form.Group as={Row} controlId="snoozeinterval">
                    <Form.Label column sm="4" className="noWrap">Snooze Interval</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        Please enter the snooze interval(in days) which you want for your static threshold alerts.
                                    </Popover.Content>
                                </Popover>
                            }>
                            <Form.Control type="number" min={1} value={snoozeInterval} onChange={this.onSnoozeIntervalChange} />
                        </OverlayTrigger>
                        {!isSnoozeIntervalValid && <ValidationWarning text={"Positive integers only"} />}
                    </Col>
                </Form.Group>}

                {this.AlertNotificationModal()}
            </React.Fragment>
        )
    }

    renderDeleteAlertModal = () => {
        const { showDeleteModal } = this.state
        return (
            <Modal show={showDeleteModal} onHide={this.toggleDeleteModal}>
                <Modal.Header>
                    Are you sure you want to delete this alert?
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.onDeleteItem}>
                        Yes
                    </Button>
                    <Button variant="light" onClick={this.toggleDeleteModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { showModal, toggleModal, charts, chart, tab, thresholdList, originator } = this.props
        const { editPage, editStatus, editIndex, uploading, dynamicList, staticList, goallineName, goal, color, axis, lineType, goallineList,
            isGoalLineValid, isGoalLineNameValid, error, unSave } = this.state

        let legendoption = (originator.OriginatorName === "MSR") ? [] : ((tab === "dailymetrics") ? [] : [{ label: '(Global)', value: null }])
        if (originator.OriginatorName === "Dashboard") {
            for (let chart of charts) {
                for (let series of chart.serverData) {
                    if (series.ID !== "0") legendoption.push({ label: series.Name, value: transformBackLegend(series.Name) })
                }
            }
        }
        if (originator.OriginatorName === "MSR") {
            for (var ring of Object.keys(thresholdList)) {
                legendoption.push({ label: ring, value: transformBackLegend(ring) })
            }
        }

        return (
            <Container>
                <Row>{this.showDeleteAlertModal}</Row>
                <Modal show={showModal} onHide={toggleModal} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert Management</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row> <Col sm="12"> {error && <Alert variant='danger' className="dashboardAlert" onClose={this.closeAlert} dismissible> <p> {error} </p> </Alert>} </Col> </Row>
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <Card.Header>Alert list</Card.Header>
                                    <Card.Body>
                                        <AlertList key={`DynamicAlertList-${chart.id}`} alertList={dynamicList} type="DynamicAlert" toggleAlert={this.toggleAlert} changeItem={this.changeItem} originator={originator} />
                                        <AlertList key={`StaticAlertList-${chart.id}`} alertList={staticList} type="StaticAlert" toggleAlert={this.toggleAlert} changeItem={this.changeItem} originator={originator} />
                                        <GoalLineList key={`GoalLineList-${chart.id}`} alertList={goallineList} type="GoalLine" toggleAlert={this.toggleAlert} changeItem={this.changeItem} />
                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonGroup>
                                            <DropdownButton id="dropdown-alert-button" title="Add" variant="primary">
                                                {originator.OriginatorName === "Dashboard" && <Dropdown.Item variant="light" onClick={this.changeAlertPage.bind(this, 'DynamicAlert')}>Dynamic Alert</Dropdown.Item>}
                                                <Dropdown.Item variant="light" onClick={this.changeAlertPage.bind(this, 'StaticAlert')}>Static Alert</Dropdown.Item>
                                                {originator.OriginatorName === "Dashboard" && <Dropdown.Item variant="light" onClick={this.changeAlertPage.bind(this, 'GoalLine')}>Goal Line</Dropdown.Item>}
                                            </DropdownButton>
                                            <Button variant="danger" onClick={this.toggleDeleteModal} disabled={uploading}> Delete {uploading && <Spinner animation="border" variant="primary" size="sm" />} </Button>
                                        </ButtonGroup>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <Card.Header>{editPage ? (editStatus === "Edit" ? "Edit " + editPage + " [" + editIndex + "]" : "New " + editPage) : "Alert Edit"}</Card.Header>
                                    <Card.Body>
                                        <React.Fragment>
                                            {(editPage === "DynamicAlert") && this.DynamicAlertEdit(legendoption)}
                                            {(editPage === "StaticAlert") && this.StaticAlertEdit(legendoption)}
                                            {(editPage === "GoalLine") &&
                                                <GoalLineEdit goallineName={goallineName} isGoalLineNameValid={isGoalLineNameValid} axis={axis} goal={goal} isGoalLineValid={isGoalLineValid} color={color} lineType={lineType}
                                                    onGoalLineChange={this.onGoalLineChange} onGoalChange={this.onGoalChange} onColorChange={this.onColorChange} onAxisChange={this.onAxisChange} onLineTypeChange={this.onLineTypeChange} />}
                                        </React.Fragment>
                                    </Card.Body>
                                    <Card.Footer>
                                        {editStatus === "Edit" ? <Button variant="primary" onClick={this.editAlert} disabled={!unSave || uploading}> Save {uploading && <Spinner animation="border" variant="primary" size="sm" />} </Button>
                                            : <Button variant="primary" onClick={this.addAlert} disabled={uploading}> Add {uploading && <Spinner animation="border" variant="primary" size="sm" />} </Button>} {' '}
                                        <Button variant="light" onClick={this.togglePage}> Cancel </Button>
                                        {unSave ? <Badge variant="secondary">Unsaved</Badge> : null}
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={toggleModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                    <Row>
                        {this.renderDeleteAlertModal()}
                    </Row>
                </Modal>
            </Container>

        )
    }
}

export default DataSuiteIndividualAlertModal

class AlertList extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { alertList, type, toggleAlert, changeItem, originator } = this.props
        return (
            <React.Fragment>
                {alertList.length === 0 ? null : <label style={{ padding: "5px" }}>{type}</label>}
                <ListGroup>
                    {alertList.map((alert, index) => (
                        <ListGroup.Item action onClick={changeItem.bind(this, type, index)} key={`${type}-${alert.Id}-${index}`}>
                            <div>
                                {transformLegend(alert.Legend, originator.OriginatorName)}
                                {!alert.valid && <Badge variant="danger">Invalid</Badge>}
                                <Form.Switch style={{ float: "right" }} id={`${type}-${alert.Id}-${index}`} key={`${type}-${alert.Id}-${index}`} label={"Enable"} onChange={toggleAlert.bind(this, type, index)} checked={alert.IsAlertEnabled} />
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </React.Fragment>
        )
    }
}

class GoalLineList extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { alertList, type, toggleAlert, changeItem } = this.props
        return (
            <React.Fragment>
                {alertList.length === 0 ? null : <label style={{ padding: "5px" }}>{type}</label>}
                <ListGroup>
                    {alertList.map((alert, index) => (
                        <ListGroup.Item action onClick={changeItem.bind(this, type, index)} key={`${type}-${alert.Id}-${index}`}>
                            <Form>
                                {alert.Name}
                                <FontAwesomeIcon icon={faGripLines} style={{ color: alert.Color }} />
                                <Form.Switch style={{ float: "right" }} id={`${type}-${alert.Id}-${index}`} key={`${type}-${alert.Id}-${index}`} label={(alert.IsAlertEnabled || alert.IsGoalLineEnabled) ? "Disable" : "Enable"} onChange={toggleAlert.bind(this, type, index)} checked={(alert.IsAlertEnabled || alert.IsGoalLineEnabled)} />
                            </Form>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </React.Fragment>
        )
    }
}

class GoalLineEdit extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { goallineName, isGoalLineNameValid, axis, goal, isGoalLineValid, color, lineType,
            onGoalLineChange, onGoalChange, onColorChange, onAxisChange, onLineTypeChange } = this.props

        return (
            <React.Fragment>
                <Form.Group as={Row} controlId="goalline">
                    <Form.Label column sm="4">Name</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" placeholder="Goal line" value={goallineName} onChange={onGoalLineChange} />
                        {!isGoalLineNameValid && <ValidationWarning text={'Goal line name can only include letters, numbers or spaces.'} />}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="goal">
                    <Form.Label column sm="4">Goal</Form.Label>
                    <Col sm="8">
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Content>
                                        Please enter the goal number which you want to be drawn on the chart.
                                    </Popover.Content>
                                </Popover>
                            }>
                            {axis === 'Ratio' ? <Form.Control type="number" value={goal} step={0.001} min={0} max={1} onChange={onGoalChange} />
                                : <Form.Control type="number" value={goal} min={0} onChange={onGoalChange} />}
                        </OverlayTrigger>
                        {!isGoalLineValid && <ValidationWarning text={axis === 'Ratio' ? 'Please input a valid decimal between 0 and 1' : 'Non-negative Integers only'} />}
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="color">
                    <Form.Label column sm="4">Color</Form.Label>
                    <Col sm="8">
                        <ColorPicker color={color} style={{ height: '100%' }} onChange={onColorChange} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="axis">
                    <Form.Label column sm="4">Axis</Form.Label>
                    <Col sm="8">
                        <DropdownButton variant="outline-secondary" title={axis}>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Latency')}>Latency</Dropdown.Item>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Count')}>Count</Dropdown.Item>
                            <Dropdown.Item onClick={onAxisChange.bind(this, 'Ratio')}>Ratio</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="linetype">
                    <Form.Label column sm="4">Line Type</Form.Label>
                    <Col sm="8">
                        <DropdownButton variant="outline-secondary" title={lineType}>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Solid')}>Solid</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Dash')}>Dash</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'DashDot')}>DashDot</Dropdown.Item>
                            <Dropdown.Item onClick={onLineTypeChange.bind(this, 'Dot')}>Dot</Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Form.Group>
            </React.Fragment>
        )
    }
}