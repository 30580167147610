import React, { Fragment } from 'react'
import { Row, Button, Jumbotron } from 'react-bootstrap';
import './EvoServiceRegistration.css';
import SectionStatus from './SectionStatus';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTrashAlt, faBan, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faSave,faPlus);

function ServiceLogCube(props) {
    const { isEditable, cubeId, cubeName, cubeStatus, sectionStatus, onCreateCube, onDeprecateCube, onDeleteCube } = props;

    const renderActionButtons = () => {
        if (cubeStatus === 'Active' && cubeId !== -1) {
            return <Fragment>
                <Button variant="outline-warning" onClick={onDeprecateCube.bind(this, cubeId)}><FontAwesomeIcon icon={faBan}/>&ensp;Deprecate Cube</Button>
                <Button variant="outline-danger" onClick={onDeleteCube.bind(this, cubeId)}><FontAwesomeIcon icon={faTrashAlt}/>&ensp;Delete Cube</Button>
            </Fragment>;
        } else if (cubeStatus === 'Deprecated' && cubeId !== -1) {
            return <Fragment>
                <Button variant="outline-danger" onClick={onDeleteCube.bind(this, cubeId)}><FontAwesomeIcon icon={faTrashAlt}/>&ensp;Delete Cube</Button>
            </Fragment>;
        } else {
            return <Fragment>
                <Button variant="outline-primary" onClick={onCreateCube}><FontAwesomeIcon icon={faPlus}/>&ensp;Create Cube</Button>
            </Fragment>;
        }
    };

    const renderCubeState = () => {
        if (cubeStatus === 'Active' && cubeId !== -1) {
            return <Fragment>
                <span style={{color: '#28a745'}} role="status" aria-atomic="true"><FontAwesomeIcon icon={faCheckCircle}/></span>
                Cube {cubeName} has been created for service log and is currently active.
            </Fragment>;
        } else if (cubeStatus === 'Deprecated' && cubeId !== -1) {
            return <Fragment>
                <span style={{color: '#ffc107'}} role="status" aria-atomic="true"><FontAwesomeIcon icon={faBan}/></span>
                Cube {cubeName} has been created for service log and is currently deprecated.
            </Fragment>;
        } else {
            return <Fragment>
                <span style={{color: '#2684ff'}} role="status" aria-atomic="true"><FontAwesomeIcon icon={faExclamationCircle}/></span>
                No cube is created for this service log.
            </Fragment>;
        }
    };

    return (
        <Fragment>
            <Row className="service-log-cube-wrapper">
                <div className="service-log-cube-state">
                    {renderCubeState()}
                </div>

                {isEditable &&
                <div className="empty-prompt-button-wrapper" style={{marginBottom: 15}}>
                    {renderActionButtons()}
                </div>}

                <SectionStatus text={sectionStatus[0]} type={sectionStatus[1]}/>
            </Row>
        </Fragment>
    );
}

export default ServiceLogCube;