import React from 'react';
import { useEffect, useState, Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlus } from '@fortawesome/free-solid-svg-icons';

function SummaryDropdown({ summary, contentList }) {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            {summary}
            <FontAwesomeIcon color="gray" icon={faPlus} onClick={toggleVisibility}></FontAwesomeIcon>
            {isVisible && (
                <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                    {contentList.map((content, index) => (
                        <li key={index} style={{ margin: '5px 0' }}>
                            {content}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SummaryDropdown;